.homeContainer {
  transition: background-color 0.5s linear;
  background-color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  color: #ffffff;
  /*.pie-de-pagina {
    height: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3em;
    margin-right: 3em;
    a {
      .indicador1 {
        font-size: 1rem;
        color: #ffffff;
        font-family: "Open Sans", sans-serif;
      }
    }
  }*/
}
.homeContainer .hide {
  display: hidden;
  opacity: 0;
}
.homeContainer a {
  color: #ffffff;
}
.homeContainer .fondo {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 4.2rem;
}
.homeContainer .fondo .imagenFondo {
  max-width: 100%;
  height: auto;
  width: clamp(15rem, 5rem + 20vw, 25rem);
  aspect-ratio: 399/298;
}
.homeContainer .fondo .centeredContainer {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}
.homeContainer .fondo .centeredContainer .lightSwitchContainer {
  width: 4.125rem;
  height: 7.5rem;
  border: 1px solid white;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.homeContainer .fondo .centeredContainer .lightSwitchContainer img {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2rem;
}
.homeContainer .fondo .centeredContainer .lightSwitchContainer .lightSwitchON {
  width: 4.125rem;
  height: 5.5rem;
  padding: 0;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: transform 0.2s linear;
}
.homeContainer .fondo .centeredContainer .lightSwitchContainer .lightSwitchOFF {
  width: 4.125rem;
  height: 5.5rem;
  padding: 0;
  position: absolute;
  bottom: 0;
  opacity: 1;
  transition: transform 0.2s linear;
}
.homeContainer .fondo .centeredContainer .lightSwitchContainer .lightSwitchONActive {
  opacity: 1;
  transform: translateY(-2rem);
}
.homeContainer .fondo .centeredContainer .lightSwitchContainer .lightSwitchOFFInactive {
  opacity: 0;
  transform: translateY(-2rem);
}
.homeContainer .fondo .centeredContainer .borderLightMode {
  border: 1px solid black !important;
}
.homeContainer .fondo .centeredContainer .focoContainer {
  width: clamp(20rem, 5rem + 25vw, 30rem);
  height: clamp(20rem, 5rem + 25vw, 30rem);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.homeContainer .fondo .centeredContainer .focoContainer .focoDark {
  position: absolute;
  top: 10%;
  width: 70%;
  height: 70%;
  opacity: 1;
}
.homeContainer .fondo .centeredContainer .focoContainer .focoLight {
  position: absolute;
  top: 10%;
  width: 70%;
  height: 70%;
  opacity: 0;
}
.homeContainer .fondo .centeredContainer .switch .focoLight {
  opacity: 1 !important;
}
.homeContainer .fondo .centeredContainer .switch .focoDark {
  opacity: 0 !important;
}
.homeContainer .fondo .centeredContainer .text {
  font-size: 1rem;
  width: 80%;
  height: auto;
  color: #eb5500;
  font-family: "Encode Sans Condensed", sans-serif;
  text-align: center;
  cursor: default;
}
.homeContainer .text-button {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: rgb(146, 146, 146);
}
.homeContainer .text-button p {
  font-family: "Open Sans", sans-serif;
  cursor: default;
}
.homeContainer .text-button .proyectos--button {
  display: flex;
  height: auto;
  width: -moz-fit-content;
  width: fit-content;
}
.homeContainer .text-button .proyectos--button .proyectosButton {
  border: 1px solid white;
}
.homeContainer .text-button .proyectos--button a {
  color: rgb(146, 146, 146);
  padding: 10px 20px;
  transition: all 0.5s linear;
}
.homeContainer .text-button .proyectos--button a:hover {
  color: black;
}
.homeContainer .text-button h1 {
  font-size: 12rem;
  font-family: HumaneRegular;
  font-weight: 100;
  line-height: 1.1;
  margin: 0;
  cursor: default;
}
.homeContainer .text-button .servicios {
  padding-bottom: 5rem;
}
.homeContainer .text-button .servicios h1 {
  font-size: 11rem;
  opacity: 0.1;
  transition: opacity 0.5s ease;
}
.homeContainer .text-button .servicios h1:hover {
  opacity: 1;
}
.homeContainer .text-button .conoce {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.homeContainer .text-button .conoce p {
  color: rgb(235, 85, 0);
}
.homeContainer .text-button .conoce h1 {
  font-size: 12rem;
}
.homeContainer .text-button .conoce a:first-of-type > h1 {
  padding-bottom: 3rem;
}
.homeContainer .text-button .conoce .link {
  cursor: pointer;
}
.homeContainer .colaboradores {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-bottom: 3rem;
}
.homeContainer .colaboradores h1 {
  margin: 0%;
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: 14rem;
  color: rgb(255, 255, 255);
  padding: 0 1rem;
  cursor: default;
}
.homeContainer .colaboradores .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: clamp(1rem, 4vw, 5rem);
  flex-direction: row;
}
.homeContainer .colaboradores .cards .card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: clamp(10rem, 17vw, 17.2rem);
  height: clamp(10rem, 17vw, 17.2rem);
  border: 1px solid #6e6e6e;
  border-radius: 10px;
}
.homeContainer .colaboradores .cards .card img {
  opacity: 0.42;
  width: clamp(5rem, 9vw, 9.375rem);
  height: auto;
  filter: grayscale(100%);
}
.homeContainer .colaboradores .cards-mobile {
  display: none;
  gap: 1rem;
}
.homeContainer .colaboradores .cards-mobile .card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6e6e6e;
  border-radius: 10px;
}
.homeContainer .colaboradores .cards-mobile .card img {
  opacity: 0.42;
}
.homeContainer .contactos {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 2em;
  margin-right: 2em;
  padding-bottom: 5rem;
}
.homeContainer .contactos .ubi {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-family: NohemiRegular;
}
.homeContainer .contactos .ubi p {
  font-size: 1rem;
  color: #eb5500;
  height: -moz-fit-content;
  height: fit-content;
  cursor: default;
}
.homeContainer .contactos .ubi h2 {
  font-size: 1.25rem;
  font-weight: 600;
  cursor: default;
}
.homeContainer .contactos .ubi .telefonos {
  display: flex;
  gap: 1rem;
}
.homeContainer .contactos .ubi .telefonos .mati:hover {
  color: #eb5500;
  cursor: pointer;
}
.homeContainer .contactos .ubi .telefonos .tomy:hover {
  color: #eb5500;
  cursor: pointer;
}
.homeContainer .contactos .ubi svg {
  width: 2rem;
  height: 3rem;
}
.homeContainer .title-contenedor {
  display: flex;
  text-align: center;
  justify-content: center;
}
.homeContainer .title-contenedor .title {
  font-size: 11rem;
  font-family: HumaneRegular;
  font-weight: 100;
}
.homeContainer .title-contenedor .title .letter {
  transition: transform 0.4s ease;
  display: inline-block;
}
.homeContainer .title-contenedor .title .letter:hover {
  transform: scale(1.25) translateY(0.8rem);
}

.containerLightMode {
  background-color: white !important;
  color: black !important;
}
.containerLightMode .pie-de-pagina a .indicador1 {
  color: black !important;
}
.containerLightMode a {
  color: black !important;
}
.containerLightMode .text-button .proyectos--button .proyectosButton {
  border: 1px solid black;
}
.containerLightMode .text-button .proyectos--button .proyectosButton:hover a {
  color: white !important;
}
.containerLightMode .text-button .servicios h1 {
  opacity: 1;
  transition: all 0.5s linear;
}
.containerLightMode .text-button .servicios h1:hover {
  color: black;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

span {
  width: -moz-max-content;
  width: max-content;
  margin: 0.1rem;
  padding: 0;
}

@media (max-width: 800px) {
  .homeContainer .fondo .imagenFondo {
    width: clamp(12.1875rem, 5rem + 30vw, 25rem);
  }
  .homeContainer .centeredContainer .lightSwitchContainer {
    position: relative !important;
    margin-top: 4rem;
    border: 0 !important;
  }
  .homeContainer .text-button .servicios {
    padding-bottom: 3rem;
  }
  .homeContainer .text-button .servicios h1 {
    font-size: clamp(5rem, 2rem + 10vw, 10rem);
    opacity: 1;
  }
  .homeContainer .text-button .conoce h1 {
    font-size: clamp(5rem, 2rem + 10vw, 10rem);
  }
  .homeContainer .colaboradores h1 {
    font-size: clamp(7rem, 3rem + 20vw, 14rem);
  }
  .homeContainer .colaboradores .cards {
    display: none;
    justify-content: center;
    align-items: center;
    gap: clamp(1rem, 2vw, 1.5rem);
    flex-direction: row;
  }
  .homeContainer .colaboradores .cards .card {
    width: clamp(5rem, 35vw, 10rem);
    height: clamp(5rem, 35vw, 10rem);
  }
  .homeContainer .colaboradores .cards .card img {
    opacity: 0.42;
    width: clamp(3rem, 12vw, 5rem);
    height: auto;
  }
  .homeContainer .colaboradores .cards-mobile {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .homeContainer .colaboradores .cards-mobile .card {
    width: 10rem;
    height: 10rem;
  }
  .homeContainer .colaboradores .cards-mobile .card img {
    opacity: 0.42;
    width: 5rem;
    height: auto;
  }
  .homeContainer .contactos {
    flex-direction: column;
    gap: 4rem;
    padding-bottom: 3rem;
  }
  .homeContainer .contactos .ubi {
    height: auto;
    gap: 1rem;
  }
  .homeContainer .contactos .ubi img {
    width: 5rem;
    height: 5rem;
  }
  .homeContainer .contactos .ubi h2 {
    font-size: clamp(1rem, 0.5rem + 4vw, 2rem);
  }
  .homeContainer .title-contenedor .title {
    font-size: clamp(5rem, 2rem + 10vw, 10rem);
  }
}/*# sourceMappingURL=home.css.map */