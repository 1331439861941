.imagen-de-cliente-contenedor {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.imagen-de-cliente-contenedor .contenedor-hide {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}/*# sourceMappingURL=ImagenCambiaEnHover.css.map */