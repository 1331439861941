.rayitas-naranjas {
  width: max-content;
  height: max-content;
  position: relative;
  bottom: 0;
  img {
    width: 6rem;
    height: 7rem;
    padding-top: 30rem;
  }
}
.trabajosContainer {
  background-color: white;
  padding-top: 6.25rem;
  height: 100vh;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  .contenedor-canchero {
    width: 100%;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    align-items: center;
    gap: 4rem;
    img {
      justify-self: flex-end;
      width: 7.1875rem;
      height: 8.4375rem;
    }
    .contenedor-texto {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      cursor: default;
    }
  }
}

@media (max-width: 800px) {
  .trabajosContainer {
    grid-template-rows: repeat(5, 1fr) 2fr;
    .contenedor-canchero {
      grid-template-columns: 1fr !important;
      grid-template-rows: 1fr 1fr;
      gap: 0;
      .contenedor-texto {
        justify-self: center;
        grid-row: 1;
        grid-column: 1;
        font-size: 1.25rem;
        font-family: NohemiRegular;
      }
      img {
        grid-row: 2;
        grid-column: 1;
        justify-self: center;
        padding-bottom: 2rem;
      }
    }
  }
}
