.dachbone-main {
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-family: NohemiRegular;
  cursor: default;
  overflow: hidden;
}
.dachbone-main .dachbone-contenedor {
  background-color: white;
  width: 100%;
  display: grid;
  padding: 0 4rem;
  padding-top: 8.4rem;
  grid-template-columns: 1.2fr 0.8fr;
  min-height: 30rem;
}
.dachbone-main .dachbone-titulo {
  position: relative;
  padding-bottom: 5rem;
  padding-right: 5rem;
}
.dachbone-main .dachbone-titulo h5 {
  font-size: clamp(3rem, 6vw, 7rem);
  margin: 0%;
  padding: 0%;
  line-height: 100%;
}
.dachbone-main .dachbone-titulo span {
  padding-left: 3.5rem;
}
.dachbone-main .dachbone-titulo .dachbone-rayita {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 20%;
}
.dachbone-main .dachbone-text {
  font-size: 1rem;
  margin: 0 auto;
  max-width: 25rem;
  font-family: NohemiRegular;
}
.dachbone-main .dachbone-video-contenedor {
  width: 100%;
  height: 50rem;
  display: flex;
  justify-content: space-around;
  position: relative;
  background-size: cover;
  background-position: center;
}
.dachbone-main .dachbone-video-contenedor .dachbone-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.dachbone-main .dachbone-video-titulo {
  position: absolute;
  top: 10rem;
  left: 5rem;
}
.dachbone-main .dachbone-video-text {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  max-width: 400px;
  position: absolute;
  bottom: 10rem;
  left: 10rem;
}
.dachbone-main .dachbone-video-text p {
  font-size: 1.1rem;
  margin: 0%;
  padding: 0%;
  color: white;
}
.dachbone-main .dachbone-video-titulo h1 {
  font-size: clamp(4rem, 9vw, 9rem);
  color: white;
  line-height: 90%;
  mix-blend-mode: difference;
}
.dachbone-main .dachbone-carpincho-contenedor {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4rem;
  padding-top: 4rem;
  margin: 0 auto;
  gap: 6rem;
}
.dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho img {
  width: clamp(25rem, 47vw, 46.75rem);
  height: auto;
}
.dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho-text {
  display: flex;
  gap: 4rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.dachbone-main .dachbone-carpincho-text p {
  max-width: 43.75rem;
  font-size: 1.5rem;
}
.dachbone-main .dachbone-sobre-el-proyecto-rayita {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3rem;
}
.dachbone-main .dachbone-sobre-el-proyecto-rayita h1 {
  display: none;
}

.dachbone-main .dachbone-sobre-el-proyecto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4rem;
  padding-bottom: 2rem;
  gap: 4rem;
}
.dachbone-main .dachbone-sobre-el-proyecto h1 {
  color: rgb(0, 0, 0);
  font-family: NohemiRegular;
  font-size: 7rem;
  margin: 0%;
  padding: 0%;
  line-height: 8rem;
  font-weight: 600;
  text-transform: uppercase;
}
.dachbone-main .dachbone-sobre-el-proyecto p {
  font-size: 1.5rem;
  font-family: NohemiRegular;
  max-width: 600px;
}

.dachbone-main .dachbone-galeria {
  background-color: white;
  display: grid;
  width: 100%;
  padding: 4rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}
.dachbone-main .dachbone-galeria .china {
  grid-column: 1 / span 3;
  grid-row: 1;
  width: 100%;
  height: 100%;
}
.dachbone-main .dachbone-galeria .viejo {
  grid-column: 1 / span 3;
  grid-row: 2;
  width: 100%;
  height: 100%;
}
.dachbone-main .dachbone-galeria .robotmujer {
  grid-column: 4 / span 2;
  grid-row: span 2;
  width: 100%;
  height: 100%;
}
.dachbone-main .dachbone-galeria .manos {
  grid-column: 1 / span 2;
  grid-row: 3 / span 2;
  width: 100%;
  height: 100%;
}
.dachbone-main .dachbone-galeria .textos {
  grid-column: 3 / span 3;
  grid-row: 3 / span 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.dachbone-main .dachbone-galeria .textos .centered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  font-family: NohemiRegular;
  max-width: 37.5rem;
}
.dachbone-main .dachbone-galeria .textos h1 {
  font-size: clamp(2rem, 6vw, 6rem);
  font-weight: 400;
  display: none;
}
.dachbone-main .dachbone-galeria .textos p {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  font-weight: 400;
}
.dachbone-main .dachbone-galeria-text-img {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dachbone-main .dachbone-video-contenedor .hide-on-desktop {
  display: none;
  flex-direction: column;
  background: none;
  position: relative;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  width: 100%;
  height: 100%;
  padding: 0;
  gap: 4rem;
}
.dachbone-main .dachbone-video-contenedor .hide-on-desktop .lowerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.dachbone-main > .dachbone-texto {
  font-family: NohemiRegular;
  font-size: 1.5rem;
  max-width: 40rem;
  padding: 0 2rem;
  text-align: center;
  display: none;
}

.dachbone-main .dachbone-background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(11, 11, 11, 0) 10.41%,
    rgba(11, 11, 11, 0) 10.41%,
    #0b0b0b 100.02%,
    #0b0b0b 100.86%
  );
  z-index: 1;
  display: none;
}
@media (max-width: 1400px) {
  .dachbone-main .dachbone-carpincho-contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho-text {
    align-items: center;
  }
  .dachbone-main .dachbone-carpincho-text p {
    max-width: 56.25rem;
  }
  .dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho img {
    width: clamp(20rem, 70vw, 70rem);
  }
}

@media (max-width: 800px) {
  .dachbone-main {
    background-color: #0b0b0b;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    text-align: center;
  }
  .dachbone-main .dachbone-background-gradient {
    display: block;
  }
  .dachbone-main .dachbone-contenedor {
    display: none;
  }
  .dachbone-main .pie-de-pagina-b {
    display: none;
  }
  .dachbone-main .dachbone-video-contenedor .hide-on-desktop {
    display: flex;
  }
  .dachbone-main .dachbone-video-contenedor {
    padding-top: 6.5rem;
  }
  .dachbone-main .dachbone-video-contenedor .hide-on-desktop {
    display: flex;
    flex-direction: column;
    background: none;
    position: relative;
    align-items: center;
    z-index: 5;
    width: 100%;
    height: 100%;
    padding: 0;
    gap: 4rem;
  }

  .dachbone-main .dachbone-video-contenedor .hide-on-desktop h5 {
    line-height: 100%;
    font-size: clamp(3rem, 12vw, 4.75rem);
    mix-blend-mode: difference;
  }
  .dachbone-main .dachbone-video-contenedor .hide-on-desktop .dachbone-titulo {
    padding: 0;
    align-items: center;
  }
  .dachbone-main .dachbone-video-contenedor .hide-on-desktop .dachbone-rayita {
    font-size: 7.875rem;
    font-family: NohemiRegular;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .dachbone-main > .dachbone-texto {
    display: block;
  }
  .dachbone-main .dachbone-video-contenedor .hide-on-mobile {
    display: none;
  }
  .dachbone-main .dachbone-carpincho-contenedor {
    background-color: #0b0b0b;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 4rem;
    height: auto;
  }
  .dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho {
    width: 100%;
  }
  .dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho img {
    width: 100%;
  }
  .dachbone-main .dachbone-carpincho-contenedor .dachbone-carpincho-text {
    display: flex;
    align-items: center;
    padding: 0 2rem;
  }
  .dachbone-main .dachbone-sobre-el-proyecto-rayita {
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .dachbone-main .dachbone-sobre-el-proyecto-rayita h1 {
    display: flex;
    font-size: 4.75rem;
    font-family: NohemiRegular;
    width: fit-content;
    text-align: center;
    mix-blend-mode: difference;
  }
  .dachbone-main .dachbone-sobre-el-proyecto-rayita img {
    position: absolute;
    justify-content: center;
    align-items: center;
  }
  .dachbone-main .dachbone-sobre-el-proyecto h1 {
    display: none;
  }
  .dachbone-main .dachbone-galeria {
    display: flex;
    flex-direction: column;
    background: none;
    padding: 2rem 0;
  }
  .dachbone-main .dachbone-galeria .textos {
    padding: 0 2rem;
  }
  .dachbone-main .dachbone-galeria .textos img {
    display: none;
  }
  .dachbone-main .dachbone-galeria .textos h1 {
    font-size: clamp(3rem, 12vw, 6rem);
    display: block;
    text-align: center;
  }
  .dachbone-main .dachbone-galeria .textos p {
    font-size: 1.2rem;
    text-align: center;
  }
  .dachbone-main .dachbone-galeria .robotmujer {
    display: none;
  }
}
