.iaproyectos-main {
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-family: NohemiRegular;
  overflow: hidden;
}
.iaproyectos-main .iaproyectos-contenedor {
  background-color: white;
  width: 100%;
  display: grid;
  padding: 0 4rem;
  padding-top: 8.4rem;
  grid-template-columns: 1.2fr 0.8fr;
}
.iaproyectos-main .iaproyectos-contenedor .iaproyectos-titulo {
  position: relative;
  padding-bottom: 5rem;
  padding-right: 5rem;
}
.iaproyectos-main .iaproyectos-contenedor .iaproyectos-titulo h5 {
  font-size: clamp(3rem, 6vw, 7rem);
  margin: 0%;
  padding: 0%;
  line-height: 100%;
  cursor: default;
}
.iaproyectos-main .iaproyectos-contenedor .iaproyectos-titulo span {
  padding-left: 3.5rem;
}
.iaproyectos-main
  .iaproyectos-contenedor
  .iaproyectos-titulo
  .iaproyectos-rayita {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 20%;
}
.iaproyectos-main .iaproyectos-contenedor .iaproyectos-text {
  font-size: 1rem;
  margin: 0 auto;
  max-width: 25rem;
  font-family: NohemiRegular;
  cursor: default;
}
.iaproyectos-main .iaproyectos-imagen-contenedor {
  width: 100%;
  height: 50rem;
  display: flex;
  justify-content: space-around;
  position: relative;
  background-size: cover;
  background-position: center;
}
.iaproyectos-main .iaproyectos-imagen-contenedor .iaproyectos-imagen {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.iaproyectos-main .iaproyectos-imagen-titulo {
  position: absolute;
  top: 10rem;
  left: 5rem;
}
.iaproyectos-main .iaproyectos-imagen-text {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  max-width: 400px;
  position: absolute;
  bottom: 10rem;
  left: 10rem;

}
.iaproyectos-main .iaproyectos-imagen-text p {
  font-size: 1.1rem;
  margin: 0%;
  padding: 0%;
  color: white;
  cursor: default;
}
.iaproyectos-main .iaproyectos-imagen-titulo h1 {
  font-size: clamp(4rem, 9vw, 9rem);
  color: white;
  line-height: 90%;
  mix-blend-mode: difference;
  cursor: default;
}
.iaproyectos-main .iaproyectos-carpincho-contenedor {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4rem;
  padding-top: 4rem;
  margin: 0 auto;
  gap: 6rem;
}
.iaproyectos-main .iaproyectos-carpincho-contenedor .iaproyectos-carpincho img {
  width: clamp(25rem, 47vw, 46.75rem);
  height: auto;
}
.iaproyectos-main
  .iaproyectos-carpincho-contenedor
  .iaproyectos-carpincho-text {
  display: flex;
  gap: 4rem;
  width: 100%;
  flex-direction: column;
  gap: 2rem;
}
.iaproyectos-main .iaproyectos-carpincho-text p {
  max-width: 43.75rem;
  font-size: 1.5rem;
  cursor: default;
}
.iaproyectos-main .iaproyectos-sobre-el-proyecto-rayita {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3rem;
}
.iaproyectos-main .iaproyectos-sobre-el-proyecto-rayita h1 {
  display: none;
  cursor: default;
}

.iaproyectos-main .iaproyectos-sobre-el-proyecto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 4rem;
  padding-bottom: 2rem;
  gap: 4rem;
}
.iaproyectos-main .iaproyectos-sobre-el-proyecto h1 {
  color: rgb(0, 0, 0);
  font-family: NohemiRegular;
  font-size: 7rem;
  margin: 0%;
  padding: 0%;
  line-height: 8rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: default;
}
.iaproyectos-main .iaproyectos-sobre-el-proyecto p {
  font-size: 1.5rem;
  font-family: NohemiRegular;
  max-width: 600px;
  cursor: default;
}

.iaproyectos-main .iaproyectos-galeria {
  background-color: white;
  display: grid;
  width: 100%;
  padding: 4rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
}
.iaproyectos-main .iaproyectos-galeria .china {
  grid-column: 1 / span 2;
  grid-row: 1;
  width: 100%;
  height: 100%;
}
.iaproyectos-main .iaproyectos-galeria .viejo {
  grid-column: 1 / span 2;
  grid-row: 2;
  width: 100%;
  height: 100%;
}
.iaproyectos-main .iaproyectos-galeria .robotmujer {
  grid-column: 3 / span 3;
  grid-row: span 2;
  width: 100%;
  height: 100%;
}
.iaproyectos-main .iaproyectos-galeria .manos {
  grid-column: 1 / span 2;
  grid-row: 3 / span 2;
  width: 100%;
  height: 100%;
}
.iaproyectos-main .iaproyectos-galeria .textos {
  grid-column: 3 / span 3;
  grid-row: 3 / span 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.iaproyectos-main .iaproyectos-galeria .textos .centered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  font-family: NohemiRegular;
  max-width: 37.5rem;
  cursor: default;
}
.iaproyectos-main .iaproyectos-galeria .textos h1 {
  font-size: clamp(2rem, 6vw, 6rem);
  font-weight: 400;
}
.iaproyectos-main .iaproyectos-galeria .textos p {
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  font-weight: 400;
}
.iaproyectos-main .iaproyectos-galeria-text-img {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iaproyectos-main .pie-de-pagina-b {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}
.iaproyectos-main .pie-de-pagina-b p {
  font-family: "Open Sans", sans-serif;
  color: #0b0b0b;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 62px; /* 310% */
  cursor: default;
}

.iaproyectos-main .iaproyectos-imagen-contenedor .hide-on-desktop {
  display: none;
}

.iaproyectos-main > .iaproyectos-texto {
  font-family: NohemiRegular;
  font-size: 1.5rem;
  width: 20rem;
  text-align: center;
  display: none;
  cursor: default;
}

.iaproyectos-main .iaproyectos-background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(11, 11, 11, 0) 10.41%,
    rgba(11, 11, 11, 0) 10.41%,
    #000 100.02%,
    #000 100.86%
  );
  z-index: 1;
  display: none;
}
@media (max-width: 1400px) {
  .iaproyectos-main .iaproyectos-carpincho-contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .iaproyectos-main .iaproyectos-carpincho-contenedor .iaproyectos-carpincho-text {
    align-items: center;
  }
  .iaproyectos-main .iaproyectos-carpincho-text p {
    max-width: 56.25rem;
  }
  .iaproyectos-main .iaproyectos-carpincho-contenedor .iaproyectos-carpincho img {
    width: clamp(20rem, 70vw, 70rem);
  }
}

@media (max-width: 800px) {
  .iaproyectos-main {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
  }
  .iaproyectos-main .iaproyectos-background-gradient {
    display: block;
  }
  .iaproyectos-main .iaproyectos-contenedor {
    display: none;
  }

  .iaproyectos-main .iaproyectos-imagen-contenedor .hide-on-desktop {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .iaproyectos-main .iaproyectos-imagen-contenedor {
    padding-top: 6.5rem;
  }
  .iaproyectos-main
    .iaproyectos-imagen-contenedor
    .hide-on-desktop
    .iaproyectos-contenedor {
    display: flex;
    flex-direction: column;
    background: none;
    position: relative;
    align-items: center;
    z-index: 5;
    width: 100%;
    height: 100%;
    padding: 0;
    gap: 4rem;
  }

  .iaproyectos-main
    .iaproyectos-imagen-contenedor
    .hide-on-desktop
    .iaproyectos-contenedor
    h5 {
    line-height: 100%;
    font-size: clamp(3rem, 12vw, 4.75rem);
    mix-blend-mode: difference;
  }
  .iaproyectos-main
    .iaproyectos-imagen-contenedor
    .hide-on-desktop
    .iaproyectos-contenedor
    .iaproyectos-titulo {
    padding: 0;
    align-items: center;
  }
  .iaproyectos-main
    .iaproyectos-imagen-contenedor
    .hide-on-desktop
    .iaproyectos-contenedor
    .iaproyectos-rayita {
    font-size: 7.875rem;
    font-family: NohemiRegular;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  .iaproyectos-main > .iaproyectos-texto {
    display: block;
  }
  .iaproyectos-main .iaproyectos-imagen-contenedor .hide-on-mobile {
    display: none;
  }
  .iaproyectos-main .iaproyectos-carpincho-contenedor {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 4rem;
    height: auto;
  }
  .iaproyectos-main .iaproyectos-carpincho-contenedor .iaproyectos-carpincho {
    width: 100%;
  }
  .iaproyectos-main
    .iaproyectos-carpincho-contenedor
    .iaproyectos-carpincho
    img {
    width: 100%;
  }
  .iaproyectos-main
    .iaproyectos-carpincho-contenedor
    .iaproyectos-carpincho-text {
    display: flex;
    align-items: center;
    padding: 0 2rem;
  }
  .iaproyectos-main .iaproyectos-sobre-el-proyecto-rayita {
    height: fit-content;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .iaproyectos-main .iaproyectos-sobre-el-proyecto-rayita h1 {
    display: flex;
    font-size: 4.75rem;
    font-family: NohemiRegular;
    width: fit-content;
    text-align: center;
    mix-blend-mode: difference;
  }
  .iaproyectos-main .iaproyectos-sobre-el-proyecto-rayita img {
    position: absolute;
    justify-content: center;
    align-items: center;
  }
  .iaproyectos-main .iaproyectos-sobre-el-proyecto h1 {
    display: none;
  }
  .iaproyectos-main .iaproyectos-galeria {
    display: flex;
    flex-direction: column;
    background: none;
    padding: 2rem 0;
  }
  .iaproyectos-main .iaproyectos-galeria .textos {
    padding: 0 2rem;
  }
  .iaproyectos-main .iaproyectos-galeria .textos .centered {
    align-items: center;
  }
  .iaproyectos-main .iaproyectos-galeria .textos h1 {
    font-size: clamp(3rem, 12vw, 6rem);
    text-align: center;
  }
  .iaproyectos-main .iaproyectos-galeria .textos p {
    font-size: 1.2rem;
    text-align: center;
  }
  .iaproyectos-main .iaproyectos-galeria .robotmujer {
    display: none;
  }
}
