.imagen-de-cliente-contenedor {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .contenedor-hide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
