.trabajos-contenedor-main {
  background-color: white;
  position: relative;
  overflow: hidden;
  border-bottom: #000000 solid 1px;
}
.trabajos-contenedor-main a {
  color: #000000;
}
.trabajos-contenedor-main::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  bottom: 0;
  background-color: #000000;
  transition: height 0.3s ease;
}

.trabajos-contenedor-main:hover::after {
  height: 100%;
}
.trabajos-contenedor-main:hover a h1 {
  color: white;
  z-index: 10;
}
.trabajos-contenedor-main:hover a p {
  color: rgba(235, 85, 0, 1);
  z-index: 10;
}

.trabajos-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
}
.trabajos-text h1 {
  font-size: clamp(3rem, 5vw, 5rem);
  transition: all 0.2s ease-in-out;
}
.trabajos-text p {
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, 2vw, 1.75rem);
  transition: all 0.2s ease-in-out;
}

@media (max-width: 800px) {
  .trabajos-text{
    height: 100%;
    gap: 1rem;
  }
  .trabajos-text h1 {
    font-size: clamp(2rem, 5vw, 5rem);
  }
  .trabajos-text p {
    font-size: clamp(1rem, 2vw, 1.75rem);
  }
}