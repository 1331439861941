.faq-item {
  margin-bottom: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  width: clamp(35rem, 2.292rem + 43.611vw, 54.625rem);
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, -0.133rem + 1.778vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33.952px; /* 106.099% */
  position: relative;
}

.faq-question.active {
  border-bottom: none;
  width: clamp(35rem, 2.292rem + 43.611vw, 54.625rem);
}

.abajo {
  transition: rotate 0.5s ease;
}

.hidden {
  rotate: 180deg;
}

.faq-answer {
  position: relative;
  width: clamp(32rem, -1.854rem + 45.139vw, 52.313rem);
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  border-bottom: 1px solid #cccccc71;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(0.7rem, -0.467rem + 1.556vw, 1.4rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(1.4rem, 0.197rem + 1.604vw, 2.122rem); /* 106.099% */
}

.faq-answer.open {
  max-height: 5rem !important; /* Ajusta este valor según sea necesario */
  padding: 10px;
}

@media (max-width: 1200px) {
  .faq-question {
    width: clamp(20rem, 45vw, 54.625rem);
  }

  .faq-question.active {
    border-bottom: none;
    width: clamp(20rem, 45vw, 54.625rem);
  }
  .faq-answer {
    width: clamp(18rem, 40vw, 52rem);
  }
}

@media (max-width: 800px) {
  .faq-question {
    width: 100%;
  }
  .faq-answer {
    width: 100%;
  }
  .faq-question.active {
    border-bottom: none;
    width: 100%;
  }
}
