.next-contenedor {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
   
    padding-bottom: 1rem;
}
.next {
    width: max-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.next .coso {
    color: rgb(0, 0, 0);
    font-size: clamp(1rem, 6vw, 1.5rem);
    display: flex;   
    align-items: center;
    font-size: NohemiRegular;
}
@media (max-width: 800px) {
    .next-contenedor {
        background-color: none;
        padding-bottom: 2rem;
    }
    .next .coso {
        color: rgb(235, 85, 0);

    } 
}