.servicios-explicacion {
  border-radius: 18px;
  width: 100%;
  min-height: 40rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(212, 212, 212, 0.08);
  color: white;
  position: relative;
  overflow: hidden;
}
.servicios-explicacion .doodle {
  position: absolute;
  top: 3rem;
  right: 3rem;
  width: 6.25rem;
  height: auto;
  z-index: 30;
}
.servicios-explicacion .animation-spacer {
  width: 100%;
  height: 0;
  overflow: hidden;
}
.servicios-explicacion .whiteDot {
  transform-origin: top center;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 0;
  width: 0;
  top: 10px;
  right: 0px;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
}
.servicios-explicacion .contenedor-servicios-explicacion-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 430px;
  overflow: hidden;
}
.servicios-explicacion .servicios-explicacion-image {
  width: 100%;
  height: 430px;
  opacity: 0;
  transform-origin: top center;
  background-position: center;
  background-size: cover;
  mix-blend-mode: exclusion;
  position: relative;
  z-index: 20;
}
.servicios-explicacion h1 {
  padding: 0 1rem;
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: 7rem;
}
.servicios-explicacion p {
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  padding: 0 1rem;
  padding-bottom: 2rem;
}

@media (max-width: 800px) {
  .servicios-explicacion .doodle {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */