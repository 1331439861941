@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contenedor-roles {
  background: linear-gradient(#0b0b0b, #0b0b0b 50%, #ffffff 50%, #ffffff);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: duration 4s;
  padding: 0%;
  margin: 0%;
  border-bottom: 2px solid white;
  background-size: 100% 200%;
  transition: all 0.3s linear;
  background-position: 0% 0%;
  .textInputContainer {
    height: auto;
    width: 90%;
    display: grid;
    justify-content: space-around;
    align-items: center;
    grid-template-columns: 0.5fr 2fr 0.5fr;
    gap: 1rem;
    padding: 1rem 0;
    .texto-izquierda {
      height: 1rem;
      font-family: "Encode Sans Condensed", sans-serif;
      font-weight: 100;
      font-size: 1rem;
      cursor: default;
    }
    .blanco-negro {
      color: rgb(255, 255, 255);
      transition: color 1s ease;
      z-index: 1;
    }
    .texto-medio {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-family: HumaneRegular;
        font-weight: 100;
        font-size: 9rem;
        color: rgb(255, 255, 255);
        margin: 0;
        padding: 0;
        cursor: default;
      }
    }
    .texto-derecha {
      text-align: end;
      font-family: "Encode Sans Condensed", sans-serif;
      font-weight: 100;
      font-size: 1rem;
      cursor: default;
    }
  }
  .follow {
    display: none;
    width: 200px;
    height: 200px;
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    position: absolute;
    transition: 0.2s;
    transform: translate(-50%, -50%);
    pointer-events: none;
    animation: fadeIn 0.4s ease-out forwards;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      filter: sepia(3);
      filter: grayscale(1);
    }
  }
}
.contenedor-roles:hover {
  background-position: 100% 100%;
  .blanco-negro {
    color: black !important;
  }
  .follow {
    display: block;
  }
}

@keyframes colorChange {
  0% {
    background-position: 0% 0%;
  }
  20% {
    background-position: 15% 15%;
  }
  40% {
    background-position: 10% 10%;
  }
  100% {
    background-position: 100% 100%;
  }
}

@keyframes colorChangeBackwards {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media (max-width: 800px) {
  .contenedor-roles {
    .textInputContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      .texto-izquierda {
        color: #fff;
        font-family: NohemiRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
      }
      .texto-medio {
        justify-content: flex-start;
        align-items: flex-start;
        p {
          font-size: clamp(7rem, 3rem + 7vw, 10rem);
          line-height: 100%;
        }
      }
    }
  }
}
