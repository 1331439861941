.landing-main {
  background-color: black;
  width: calc(100vw - (100vw - 100%));
  overflow: hidden;
}
.landing-main .landing-barra {
  width: 100%;
  height: 100px;
  background: rgba(15, 15, 15, 0.521);
  -webkit-backdrop-filter: blur(3.8499999046px);
          backdrop-filter: blur(3.8499999046px);
  position: fixed;
  z-index: 100;
  display: grid;
  grid-template-columns: 2fr 4fr;
  align-items: center;
}
.landing-main .landing-barra .nav-icon {
  padding-left: 2rem;
}
.landing-main .landing-barra .navegacion-barra {
  display: flex;
  width: clamp(37rem, 27.625rem + 12.5vw, 42.625rem);
  justify-content: space-between;
}
.landing-main .landing-barra .navegacion-barra button {
  border: none;
  background: none;
}
.landing-main .landing-barra .navegacion-barra .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, 0.179rem + 1.361vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 117.436% */
  cursor: pointer;
}
.landing-main .landing-barra .navegacion-barra .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, 0.179rem + 1.361vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 117.436% */
  cursor: pointer;
}
.landing-main .landing-barra .navegacion-barra .text-3 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, 0.179rem + 1.361vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 117.436% */
  cursor: pointer;
}
.landing-main .landing-barra .navegacion-barra .text-4 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, 0.179rem + 1.361vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 117.436% */
  cursor: pointer;
}
.landing-main .landing-barra-mobile {
  width: 100%;
  height: 100px;
  background: rgba(15, 15, 15, 0.521);
  -webkit-backdrop-filter: blur(3.8499999046px);
          backdrop-filter: blur(3.8499999046px);
  position: fixed;
  z-index: 100;
  display: none;
  flex-direction: column;
}
.landing-main .landing-barra-mobile .nav-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}
.landing-main .landing-barra-mobile .nav-content .menu-button {
  border: none;
  background: none;
  cursor: pointer;
}
.landing-main .landing-barra-mobile .menu-mobile {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: rgba(15, 15, 15, 0.521);
  -webkit-backdrop-filter: blur(3.8499999046px);
          backdrop-filter: blur(3.8499999046px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing-main .landing-barra-mobile .menu-mobile button {
  border: none;
  background: none;
}
.landing-main .landing-barra-mobile .menu-mobile .text {
  color: #fff;
  font-family: NohemiRegular;
  height: 40px;
  padding: 1rem 0;
}
.landing-main .landing-video video {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.landing-main .landing-trabajemos {
  width: 100%;
  height: 100vh;
  position: relative;
}
.landing-main .landing-trabajemos .textos {
  width: -moz-max-content;
  width: max-content;
  margin-left: clamp(2rem, -9.667rem + 15.556vw, 9rem);
  padding-top: clamp(4rem, -7.667rem + 15.556vw, 11rem);
}
.landing-main .landing-trabajemos .textos .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3.667rem + 4.444vw, 9rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-trabajemos .textos .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3.667rem + 4.444vw, 9rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-trabajemos .textos .text-3 {
  width: clamp(30rem, -2.292rem + 43.056vw, 49.375rem);
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.667rem + 2.222vw, 2rem);
  font-style: normal;
  font-weight: 300;
  line-height: 104%; /* 33.28px */
}
.landing-main .landing-trabajemos .textos .boton {
  display: flex;
  width: clamp(19rem, 12.229rem + 9.028vw, 23.063rem);
  height: clamp(3rem, 1.73rem + 1.694vw, 3.762rem);
  align-items: center;
  justify-content: center;
  gap: 27.69px;
  flex-shrink: 0;
  border-radius: 6.02px;
  background: #eb5500;
  margin-top: 79px;
}
.landing-main .landing-trabajemos .textos .boton p {
  padding-top: 6px;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.8rem, 0.85rem + 1.267vw, 2.37rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-trabajemos .textos .boton img {
  width: 3rem;
  height: auto;
}
.landing-main .landing-trabajemos .imagen {
  width: clamp(65rem, 38.646rem + 35.139vw, 80.813rem);
  height: clamp(38rem, 31.333rem + 8.889vw, 42rem);
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url(../../img/landing/notebook.png);
  background-position: top 20% center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-main .landing-encendamos {
  width: 90%;
  margin: 0 auto;
  height: 90svh;
  padding-top: clamp(2rem, -0.917rem + 3.889vw, 3.75rem);
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.landing-main .landing-encendamos .texto {
  width: clamp(38rem, 25.708rem + 16.389vw, 45.375rem);
  flex-shrink: 0;
  align-self: flex-start;
}
.landing-main .landing-encendamos .texto p {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, 0.333rem + 2.222vw, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .landing-encendamos .imagen {
  align-self: flex-end;
  width: clamp(42rem, 31.896rem + 13.472vw, 48.063rem);
  height: clamp(28rem, 17.375rem + 14.167vw, 34.375rem);
  background-image: url(../../img/landing/Group\ 639.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-main .landing-colaboremos {
  width: 100%;
  height: 100vh;
  margin-top: clamp(1rem, -7.125rem + 10.833vw, 5.875rem);
}
.landing-main .landing-colaboremos .titulo {
  margin-left: clamp(3rem, -8.667rem + 15.556vw, 10rem);
}
.landing-main .landing-colaboremos .titulo .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(3.3rem, 2.237rem + 1.417vw, 3.938rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .landing-colaboremos .video {
  overflow: hidden;
  width: 100%;
  height: clamp(35rem, 26.667rem + 11.111vw, 40rem);
}
.landing-main .landing-colaboremos .video video {
  width: 100%;
  margin-top: clamp(-17rem, -22rem + 6.667vw, -14rem);
  height: 68.4rem;
  background-position: center;
}
.landing-main .landing-colaboremos .parrafo {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.landing-main .landing-colaboremos .parrafo p {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.5rem, 0.667rem + 1.111vw, 2rem);
  font-style: normal;
  font-weight: 400;
  width: clamp(70rem, 20.313rem + 66.25vw, 99.813rem);
}
.landing-main .landing-tarjetas {
  width: 100%;
  padding: 0 2rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.landing-main .landing-tarjetas .tarjetas-arriba {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 {
  width: 580.583px;
  height: 204.041px;
  flex-shrink: 0;
  border-radius: 6.437px;
  border: 0.644px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .contenido {
  display: flex;
  align-items: center;
  gap: 19.31px;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .contenido p {
  color: #fff;
  text-align: start;
  font-family: NohemiRegular;
  font-size: 43.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .descripcion {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 465.367px;
  height: 63.722px;
  flex-shrink: 0;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 {
  width: 580.583px;
  height: 204.041px;
  flex-shrink: 0;
  border-radius: 6.437px;
  border: 0.644px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .contenido {
  display: flex;
  gap: 19.31px;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .contenido p {
  color: #fff;
  text-align: start;
  font-family: NohemiRegular;
  font-size: 43.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .descripcion {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 465.367px;
  height: 63.722px;
  flex-shrink: 0;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 {
  width: 580.583px;
  height: 204.041px;
  flex-shrink: 0;
  border-radius: 6.437px;
  border: 0.644px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .contenido {
  display: flex;
  align-items: center;
  display: inline-flex;
  align-items: center;
  gap: 19.31px;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .contenido p {
  color: #fff;
  text-align: center;
  font-family: NohemiRegular;
  font-size: 43.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .descripcion {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 465.367px;
  height: 63.722px;
  flex-shrink: 0;
}
.landing-main .landing-tarjetas .tarjetas-abajo {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 {
  width: 580.583px;
  height: 204.041px;
  flex-shrink: 0;
  border-radius: 6.437px;
  border: 0.644px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .contenido {
  display: flex;
  align-items: center;
  display: inline-flex;
  align-items: center;
  gap: 19.31px;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .contenido p {
  color: #fff;
  text-align: center;
  font-family: NohemiRegular;
  font-size: 43.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .descripcion {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 465.367px;
  height: 63.722px;
  flex-shrink: 0;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 {
  width: 580.583px;
  height: 204.041px;
  flex-shrink: 0;
  border-radius: 6.437px;
  border: 0.644px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .contenido {
  display: flex;
  align-items: center;
  display: inline-flex;
  align-items: center;
  gap: 19.31px;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .contenido p {
  color: #fff;
  text-align: center;
  font-family: NohemiRegular;
  font-size: 43.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .descripcion {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 465.367px;
  height: 63.722px;
  flex-shrink: 0;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 {
  width: 580.583px;
  height: 204.041px;
  flex-shrink: 0;
  border-radius: 6.437px;
  border: 0.644px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3.3rem;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .contenido {
  display: flex;
  align-items: center;
  display: inline-flex;
  align-items: center;
  gap: 19.31px;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .contenido p {
  color: #fff;
  text-align: center;
  font-family: NohemiRegular;
  font-size: 43.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .descripcion {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 465.367px;
  height: 63.722px;
  flex-shrink: 0;
}
.landing-main .landing-fulltex {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}
.landing-main .landing-fulltex .texto-2024 {
  width: 100%;
  padding-left: clamp(5rem, -3.854rem + 11.806vw, 10.313rem);
  padding-top: 2rem;
}
.landing-main .landing-fulltex .texto-2024 .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(3.8rem, 2.946rem + 1.139vw, 4.313rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.landing-main .landing-fulltex .texto-2024 .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(3.8rem, 2.946rem + 1.139vw, 4.313rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .landing-fulltex .imagen-texto {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: clamp(3rem, -3.354rem + 8.472vw, 6.813rem);
}
.landing-main .landing-fulltex .imagen-texto .div-img {
  width: 50%;
  aspect-ratio: 780/591;
  background-image: url(../../img/landing/Fulltex.png);
  background-size: cover;
  background-position: center;
}
.landing-main .landing-fulltex .imagen-texto .div-p {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 38px;
  width: 42%;
}
.landing-main .landing-fulltex .imagen-texto .div-p .titulo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2.5rem, 1.042rem + 1.944vw, 3.375rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.landing-main .landing-fulltex .imagen-texto .div-p .parrafo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .landing-baires {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  justify-content: space-evenly;
  margin-top: 11.4375rem;
}
.landing-main .landing-baires .baires-imagen {
  width: 42%;
  aspect-ratio: 780/591;
  background-image: url(../../img/landing/Baires.png);
  background-size: cover;
  background-position: center;
}
.landing-main .landing-baires .baires-texto {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 38px;
  width: 50%;
}
.landing-main .landing-baires .baires-texto .titulo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2.5rem, 1.042rem + 1.944vw, 3.375rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.landing-main .landing-baires .baires-texto .parrafo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .landing-boscova {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  justify-content: space-evenly;
  margin-top: 11.4375rem;
}
.landing-main .landing-boscova .boscova-imagen {
  width: 50%;
  aspect-ratio: 780/591;
  background-image: url(../../img/landing/Rectangle\ 33.png);
  background-size: cover;
  background-position: center;
}
.landing-main .landing-boscova .boscova-texto {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 38px;
  width: 42%;
}
.landing-main .landing-boscova .boscova-texto .titulo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2.5rem, 1.042rem + 1.944vw, 3.375rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.landing-main .landing-boscova .boscova-texto .parrafo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .landing-ana {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  justify-content: space-evenly;
  margin-top: 11.4375rem;
}
.landing-main .landing-ana .ana-imagen {
  width: 42%;
  aspect-ratio: 780/591;
  background-image: url(../../img/landing/AnaTome.png);
  background-size: cover;
  background-position: center;
}
.landing-main .landing-ana .ana-texto {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 38px;
  width: 50%;
}
.landing-main .landing-ana .ana-texto .titulo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2.5rem, 1.042rem + 1.944vw, 3.375rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.landing-main .landing-ana .ana-texto .parrafo {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .mantenimiento-formulario {
  width: 100%;
  height: 70rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
}
.landing-main .mantenimiento-formulario .formulario-borde {
  width: clamp(79rem, 12.75rem + 82.813vw, 112.125rem);
  height: auto;
  flex-shrink: 0;
  border: 1px solid #fff;
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba {
  display: flex;
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda {
  width: clamp(42rem, -2rem + 55vw, 64rem);
  height: clamp(10rem, 3rem + 9.175vw, 14rem);
  display: flex;
  flex-direction: column;
  padding: 1.44rem 5.81rem;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, -2.75rem + 5vw, 3.7rem);
  font-style: normal;
  font-weight: 500;
  line-height: 115.573%; /* 5.05631rem */
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 span {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: clamp(3rem, -1rem + 5.25vw, 5.5rem);
  font-style: normal;
  font-weight: 700;
  line-height: 115.573%;
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 0.25rem + 1vw, 1.5rem);
  font-style: normal;
  font-weight: 500;
  line-height: 115.573%; /* 2.45594rem */
  width: clamp(35rem, 5.5rem + 36.875vw, 49.75rem);
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha {
  display: flex;
  flex-direction: column;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: space-around;
  height: clamp(10rem, 3rem + 9.175vw, 14rem);
  padding: 1rem 3.69rem;
  border-bottom: 1px solid #fff;
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 0.25rem + 1vw, 1.5rem);
  font-style: normal;
  font-weight: 300;
  line-height: 115.573%; /* 2.0225rem */
}
.landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115.573%; /* 2.0225rem */
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba {
  display: flex;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda {
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25rem 1.31rem;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre {
  width: clamp(35rem, -7.12rem + 52.65vw, 56.06rem);
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.44rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 span {
  color: #eb5500;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 1.5vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda input:-internal-autofill-selected {
  background-color: red !important;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha {
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 1.25rem 1.31rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .arriba-nombre {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1.31rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 1.5vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo {
  display: flex;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda {
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25rem 1.31rem;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre {
  display: flex;
  width: clamp(35rem, -7.12rem + 52.65vw, 56.06rem);
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.44rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-2 span {
  color: #eb5500;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 1.5vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha {
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 1.25rem 1.31rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .arriba-nombre {
  display: flex;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1.31rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 1.5vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .abajo {
  width: 100%;
  height: 15rem;
  padding: 1.25rem 1.31rem;
}
.landing-main .mantenimiento-formulario .formulario-borde .abajo .texto-1 {
  margin-bottom: 1.31rem;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .mantenimiento-formulario .formulario-borde .abajo textarea {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.021);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #fff;
  resize: none;
  font-family: NohemiRegular;
  font-size: 2.4375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  padding: 0.5rem;
  overflow-y: scroll;
  border: none;
}
.landing-main .mantenimiento-formulario .botones-checkpoints {
  width: clamp(79rem, 12.75rem + 82.813vw, 112.125rem);
  height: -moz-max-content;
  height: max-content;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 1.5rem;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .izquierda {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.56rem;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-1 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-1 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 0.2rem;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-2 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-2 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-3 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-3 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-4 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-4 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-5 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-5 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo {
  transition: all 0.3s ease;
  cursor: pointer;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton {
  text-align: end;
  border: white 1px solid;
  padding-right: 2.69rem;
  padding-top: 0.38rem;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo:hover {
  background-color: #eb5500;
}
.landing-main .landing-planes {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding-top: 11rem;
}
.landing-main .landing-planes .planes-texto {
  width: -moz-max-content;
  width: max-content;
  margin-left: 9.0625rem;
}
.landing-main .landing-planes .planes-texto .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 109px;
  font-style: normal;
  font-weight: 600;
  line-height: 92px; /* 84.404% */
}
.landing-main .landing-planes .planes-texto .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 109px;
  font-style: normal;
  font-weight: 400;
  line-height: 92px;
}
.landing-main .landing-planes .planes-tarjetas {
  width: 100%;
  height: 38rem;
  display: flex;
  margin-top: 9.5rem;
  margin-bottom: 10.3125rem;
  justify-content: space-evenly;
  align-items: center;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 {
  width: clamp(21rem, -3.583rem + 32.778vw, 35.75rem);
  height: 34.125rem;
  flex-shrink: 0;
  background-image: url(../../img/landing/FondoPlanBasic.png);
  background-position: center;
  padding-top: clamp(1rem, -2.229rem + 4.306vw, 2.938rem);
  padding-left: 0.625rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .arriba .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(4rem, -0.688rem + 6.25vw, 6.813rem);
  font-style: normal;
  font-weight: 500;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .arriba .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, -0.396rem + 3.194vw, 3.438rem);
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
  margin-bottom: 2.4375rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .arriba .text-3 {
  width: 100%;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.2rem, 0.179rem + 1.361vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo {
  display: flex;
  flex-direction: column;
  padding-right: 2.0625rem;
  align-items: end;
  margin-top: clamp(2rem, -2.688rem + 6.25vw, 4.813rem);
  gap: 1.6rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios {
  display: flex;
  align-items: center;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original {
  align-self: flex-end;
  margin-right: clamp(1rem, -0.544rem + 2.058vw, 1.926rem);
  position: relative;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original .text-1 {
  color: rgba(255, 255, 255, 0.363);
  font-family: NohemiRegular;
  font-size: 17.316px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.704px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original .text-2 {
  color: rgba(235, 86, 0, 0.3294117647);
  font-family: NohemiRegular;
  font-size: 28.063px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.704px; /* 70.213% */
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original .linea {
  width: 101%;
  height: 2px;
  top: 60%;
  border-color: rgba(105, 105, 105, 0.281);
  background-color: rgb(124, 124, 124);
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .descuento .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .descuento .text-2 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 70.213% */
}
.landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .frase {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 0.792rem + 0.278vw, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 183.333% */
}
.landing-main .landing-planes .planes-tarjetas .plan-2 {
  width: clamp(21rem, -3.583rem + 32.778vw, 35.75rem);
  height: 34.125rem;
  flex-shrink: 0;
  background-image: url(../../img/landing/FondoPlanPro.png);
  background-position: center;
  padding-top: clamp(1rem, -2.229rem + 4.306vw, 2.938rem);
  padding-left: 0.625rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .arriba .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(4rem, -0.688rem + 6.25vw, 6.813rem);
  font-style: normal;
  font-weight: 500;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .arriba .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, -0.396rem + 3.194vw, 3.438rem);
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
  margin-bottom: 2.4375rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .arriba .text-3 {
  width: 100%;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(0.9rem, -0.204rem + 1.472vw, 1.563rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo {
  display: flex;
  flex-direction: column;
  padding-right: clamp(1rem, -0.771rem + 2.361vw, 2.063rem);
  align-items: end;
  margin-top: clamp(2rem, 0.438rem + 2.083vw, 2.938rem);
  gap: 1.6rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios {
  display: flex;
  align-items: center;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original {
  align-self: flex-end;
  margin-right: 1.9263rem;
  position: relative;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original .text-1 {
  color: rgba(255, 255, 255, 0.363);
  font-family: NohemiRegular;
  font-size: 17.316px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.704px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original .text-2 {
  color: rgba(235, 86, 0, 0.3294117647);
  font-family: NohemiRegular;
  font-size: 28.063px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.704px; /* 70.213% */
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original .linea {
  width: 101%;
  height: 2px;
  top: 60%;
  border-color: rgba(105, 105, 105, 0.281);
  background-color: rgb(124, 124, 124);
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .descuento .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .descuento .text-2 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 70.213% */
}
.landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .frase {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 0.792rem + 0.278vw, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 183.333% */
}
.landing-main .landing-planes .planes-tarjetas .plan-3 {
  width: clamp(21rem, -3.583rem + 32.778vw, 35.75rem);
  height: 34.125rem;
  flex-shrink: 0;
  background-image: url(../../img/landing/FondoPlanBusiness.png);
  background-position: center;
  padding-top: clamp(1rem, -2.229rem + 4.306vw, 2.938rem);
  padding-left: 0.625rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .arriba .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(4rem, -0.688rem + 6.251vw, 6.813rem);
  font-style: normal;
  font-weight: 500;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .arriba .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, -0.396rem + 3.194vw, 3.438rem);
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
  margin-bottom: 2.4375rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .arriba .text-3 {
  width: 100%;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(0.8rem, -0.471rem + 1.694vw, 1.563rem);
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo {
  display: flex;
  flex-direction: column;
  padding-right: clamp(1.5rem, 0.563rem + 1.25vw, 2.063rem);
  align-items: end;
  margin-top: 2.4375rem;
  gap: 1.6rem;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios {
  display: flex;
  align-items: center;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original {
  align-self: flex-end;
  margin-right: 1.9263rem;
  position: relative;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original .text-1 {
  color: rgba(255, 255, 255, 0.363);
  font-family: NohemiRegular;
  font-size: 17.316px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.704px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original .text-2 {
  color: rgba(235, 86, 0, 0.3294117647);
  font-family: NohemiRegular;
  font-size: 28.063px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.704px; /* 70.213% */
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original .linea {
  width: 101%;
  height: 2px;
  top: 60%;
  border-color: rgba(105, 105, 105, 0.281);
  background-color: rgb(124, 124, 124);
  position: absolute;
  z-index: 1000;
  border-radius: 2px;
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .descuento .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .descuento .text-2 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 70.213% */
}
.landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .frase {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 0.792rem + 0.278vw, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 183.333% */
}
.landing-main .landing-starter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-main .landing-starter .starter-contenedor {
  width: clamp(70rem, -0.313rem + 93.75vw, 112.188rem);
  height: 546px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  background-color: white;
  background-image: url(../../img/landing/Celular.png);
  background-position: right -0.25rem bottom -0.625rem;
  background-repeat: no-repeat;
  position: relative;
}
.landing-main .landing-starter .starter-contenedor .starter-textos {
  padding-top: 66px;
  padding-left: 13px;
}
.landing-main .landing-starter .starter-contenedor .starter-textos .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 6.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 4.5625rem; /* 84.404% */
}
.landing-main .landing-starter .starter-contenedor .starter-textos .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 3.4375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 51px; /* 167.273% */
}
.landing-main .landing-starter .starter-contenedor .starter-textos .text-3 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 4.1125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 70.213% */
  margin-top: 27px;
  margin-bottom: 10px;
}
.landing-main .landing-starter .starter-contenedor .starter-textos .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
  width: 585px;
}
.landing-main .landing-starter .starter-contenedor .starter-foco {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 75px;
  padding-top: 203px;
}
.landing-main .landing-starter .starter-contenedor .starter-foco img {
  width: 95.88px;
  height: 141px;
  flex-shrink: 0;
  margin-bottom: 30px;
}
.landing-main .landing-starter .starter-contenedor .starter-foco .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 40.6px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px; /* 113.793% */
  margin-bottom: 18px;
}
.landing-main .landing-starter .starter-contenedor .starter-foco .text-2 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 65.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px; /* 70.213% */
  margin-bottom: 18px;
}
.landing-main .landing-starter .starter-contenedor .starter-foco .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 183.333% */
}
.landing-main .landing-starter .starter-contenedor .boton {
  display: flex;
  width: 352px;
  height: 38px;
  padding: 10.293px 10.675px;
  justify-content: center;
  align-items: center;
  gap: 17.537px;
  flex-shrink: 0;
  border-radius: 3.812px;
  border: 2.276px solid #eb5500;
  position: absolute;
  bottom: 6.3125rem;
  right: 2.125rem;
}
.landing-main .landing-starter .starter-contenedor .boton a p {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 24.018px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .starter-mobile {
  display: none !important;
}
.landing-main .landing-mantenimiento {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 102px;
  position: relative;
}
.landing-main .landing-mantenimiento .mantenimiento-contenedor {
  width: clamp(70rem, -0.313rem + 93.75vw, 112.188rem);
  height: 546px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-image: url(../../img/landing/banerempresarial.png);
  background-position: center;
}
.landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos {
  padding-top: 66px;
  padding-left: 13px;
}
.landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-1 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(3rem, -0.854rem + 5.139vw, 5.313rem);
  font-style: normal;
  font-weight: 500;
  line-height: 4.5625rem;
}
.landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-2 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(2.8rem, 1.737rem + 1.417vw, 3.438rem);
  font-style: normal;
  font-weight: 300;
  line-height: 51px; /* 167.273% */
}
.landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-3 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: clamp(3.6rem, 2.746rem + 1.139vw, 4.112rem);
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 70.213% */
  margin-bottom: 10px;
}
.landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-4 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(1.5rem, 0.979rem + 0.694vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
  width: 585px;
}
.landing-main .landing-mantenimiento .boton {
  width: 545.133px;
  height: 74px;
  display: flex;
  padding: 20.045px 20.788px;
  justify-content: center;
  align-items: center;
  gap: 34.151px;
  flex-shrink: 0;
  border-radius: 7.424px;
  border: 1.233px solid #eb5500;
  position: absolute;
  bottom: 3.25rem;
  right: clamp(4rem, -54.378rem + 77.838vw, 39.027rem);
}
.landing-main .landing-mantenimiento .boton a p {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 46.772px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.landing-main .landing-clientes {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  flex-shrink: 0;
  background: #fff;
  mix-blend-mode: difference;
  padding-bottom: clamp(4rem, 1.708rem + 3.056vw, 5.375rem);
}
.landing-main .landing-clientes .texto {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  padding-left: 113px;
  padding-top: 40px;
  margin-bottom: 15px;
}
.landing-main .landing-clientes .texto .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3.25rem + 5vw, 9.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: 126px; /* 85.135% */
}
.landing-main .landing-clientes .texto .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3.25rem + 5vw, 9.25rem);
  font-style: normal;
  font-weight: 500;
  line-height: 126px;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta {
  width: 100%;
  display: flex;
  justify-content: center;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta {
  width: clamp(72rem, 7.417rem + 86.111vw, 110.75rem);
  flex-shrink: 0;
  border-top-left-radius: 29px;
  border-top-right-radius: 29px;
  background: #0e0e0e;
  display: flex;
  overflow: hidden;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-img-contenedor {
  width: 40%;
  height: auto;
  aspect-ratio: 586/685;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-img-contenedor .div-img {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background-image: url(../../img/landing/FotoFulltex.png);
  background-position: center;
  background-size: cover;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 60%;
  margin-left: 4rem;
  padding-top: clamp(9rem, 5.146rem + 5.139vw, 11.313rem);
  padding-bottom: 4rem;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .carita {
  position: absolute;
  top: 4.125rem;
  left: 0;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .manitos {
  margin-right: 62px;
  margin-bottom: 17.88px;
  gap: 1rem;
  display: flex;
  flex-direction: row;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 157.692% */
  text-transform: uppercase;
  width: 70%;
  align-self: flex-start;
  height: clamp(9rem, 3.688rem + 7.083vw, 12.188rem);
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 62px;
  gap: 1rem;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha {
  background: none;
  border: none;
  cursor: pointer;
}
.landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda {
  rotate: 180deg;
  background: none;
  border: none;
  cursor: pointer;
}
.landing-main .pregunas-frecuentes {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.landing-main .pregunas-frecuentes .text-img {
  height: 100%;
  padding-top: clamp(2rem, -3rem + 6.667vw, 5rem);
}
.landing-main .pregunas-frecuentes .text-img .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(6rem, 2.563rem + 4.583vw, 8.063rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(5.8rem, 3.592rem + 2.944vw, 7.125rem); /* 88.372% */
}
.landing-main .pregunas-frecuentes .text-img .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(6rem, 2.563rem + 4.583vw, 8.063rem);
  font-style: normal;
  font-weight: 600;
  line-height: clamp(5.8rem, 3.592rem + 2.944vw, 7.125rem);
}
.landing-main .pregunas-frecuentes .text-img .video-contenedor {
  width: clamp(40rem, 23.333rem + 22.222vw, 50rem);
  height: clamp(28rem, 24.25rem + 5vw, 30.25rem);
  overflow: hidden;
  position: relative;
}
.landing-main .pregunas-frecuentes .text-img .video-contenedor video {
  position: absolute;
  left: -5rem;
  bottom: -5rem;
  width: clamp(54rem, 45.042rem + 11.944vw, 59.375rem);
  height: auto !important;
}
.landing-main .pregunas-frecuentes .preguntas-contenedor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}
.landing-main .final {
  background-color: white;
  width: 100%;
  height: 100vh;
  margin-top: clamp(7rem, -3.938rem + 14.584vw, 13.563rem);
  padding-top: clamp(5rem, -1.875rem + 9.167vw, 9.125rem);
  position: relative;
}
.landing-main .final .gmail {
  text-align: center;
}
.landing-main .final .gmail a p {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: clamp(5rem, -0.104rem + 6.806vw, 8.063rem);
  font-style: normal;
  font-weight: 400;
  line-height: 114px; /* 88.372% */
}
.landing-main .final .node-contenedor {
  margin-top: clamp(8rem, -1.271rem + 12.361vw, 13.563rem);
  display: flex;
  justify-content: space-between;
  padding-left: clamp(4rem, -1.521rem + 7.361vw, 7.313rem);
  padding-right: 12.9375rem;
}
.landing-main .final .node-contenedor .div-img img {
  padding-left: 28px;
}
.landing-main .final .node-contenedor .div-img .text {
  width: 561px;
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 21px;
}
.landing-main .final .node-contenedor .div-derecha {
  width: 55rem;
  display: flex;
  flex-direction: column;
}
.landing-main .final .node-contenedor .div-derecha .servicios {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  padding-bottom: 35px;
}
.landing-main .final .node-contenedor .div-derecha .servicios .text-1 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .servicios .text-2 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .servicios .text-3 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .servicios .text-4 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  width: 100%;
  justify-content: center;
}
.landing-main .final .node-contenedor .div-derecha .cualidades .izquierda {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-5 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .derecha {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-5 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .planes {
  grid-column: 4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-5 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.landing-main .final .derechos-reservados {
  width: 100%;
  bottom: 1rem;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: end;
  padding-left: 85px;
  padding-right: 107px;
}
.landing-main .final .derechos-reservados p {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* 310% */
}
.landing-main .final .derechos-reservados .redes {
  height: -moz-max-content;
  height: max-content;
  display: flex;
  gap: 1rem;
}
.landing-main .final .derechos-reservados .redes div {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #0b0b0b;
}
.landing-main .final .derechos-reservados .redes div img {
  width: 22px;
  height: 22px;
}

@media (max-width: 1782px) {
  .landing-tarjetas {
    flex-direction: row !important;
  }
  .landing-tarjetas .tarjetas-arriba {
    flex-direction: column;
  }
  .landing-tarjetas .tarjetas-abajo {
    flex-direction: column;
  }
  .landing-planes .planes-tarjetas {
    height: -moz-max-content !important;
    height: max-content !important;
    gap: 5rem;
  }
  .landing-starter .starter-contenedor {
    position: relative;
  }
  .landing-starter .starter-contenedor .starter-textos .text-1 {
    font-size: clamp(5rem, -18.405rem + 21.014vw, 6.813rem) !important;
  }
  .landing-starter .starter-contenedor .starter-textos .text-2 {
    font-size: clamp(2.8rem, -5.432rem + 7.391vw, 3.438rem) !important;
  }
  .landing-starter .starter-contenedor .starter-textos .text-3 {
    font-size: clamp(3.7rem, -1.627rem + 4.783vw, 4.112rem) !important;
  }
  .landing-starter .starter-contenedor .starter-textos .text-4 {
    font-size: clamp(1.1rem, -0.049rem + 1.672vw, 1.813rem) !important;
    width: clamp(28rem, 14.19rem + 20.088vw, 36.563rem) !important;
  }
  .landing-starter .starter-contenedor .starter-foco {
    padding-top: 0% !important;
    right: 2rem;
    top: 2rem !important;
    position: absolute;
  }
  .landing-starter .starter-contenedor .boton {
    bottom: 8rem !important;
    right: 1rem !important;
  }
}
@media (max-width: 1200px) {
  .landing-main {
    position: relative;
  }
  .landing-main .landing-barra {
    grid-template-columns: 2fr 6fr;
  }
  .landing-main .landing-video {
    position: relative;
  }
  .landing-main .landing-video video {
    width: 100vw;
    height: 100vh;
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .landing-main .landing-trabajemos {
    width: 100%;
    position: absolute;
    top: 0;
  }
  .landing-main .landing-trabajemos .textos {
    position: absolute;
    margin-left: clamp(0.5rem, -0.045rem + 2.727vw, 2rem);
    padding-top: clamp(6rem, 3.455rem + 12.727vw, 13rem);
  }
  .landing-main .landing-trabajemos .textos .text-1 {
    font-size: clamp(2rem, 0.182rem + 9.091vw, 7rem);
  }
  .landing-main .landing-trabajemos .textos .text-2 {
    font-size: clamp(2rem, 0.182rem + 9.091vw, 7rem);
  }
  .landing-main .landing-trabajemos .textos .text-3 {
    padding-top: clamp(1rem, 0.636rem + 1.818vw, 2rem);
    width: clamp(20rem, 16.364rem + 18.182vw, 30rem);
  }
  .landing-main .landing-trabajemos .textos .boton {
    width: clamp(12rem, 9.455rem + 12.727vw, 19rem);
    height: clamp(2rem, 1.359rem + 3.204vw, 3.762rem);
    gap: clamp(1rem, 0.734rem + 1.328vw, 1.731rem);
    margin-top: clamp(2rem, 0.932rem + 5.341vw, 4.938rem);
  }
  .landing-main .landing-trabajemos .textos .boton p {
    font-size: clamp(1rem, 0.709rem + 1.455vw, 1.8rem);
  }
  .landing-main .landing-trabajemos .textos .boton img {
    width: clamp(0.649rem, 0.522rem + 0.638vw, 1rem);
    height: clamp(0.955rem, 0.829rem + 0.628vw, 1.3rem);
    flex-shrink: 0;
  }
  .landing-main .landing-trabajemos .imagen {
    width: clamp(20rem, 3.636rem + 81.818vw, 65rem);
    height: clamp(18rem, 10.727rem + 36.364vw, 38rem);
    position: absolute;
    right: -1rem;
    bottom: 0;
    background-image: url(../../img/landing/notebook.png);
    background-position: top 20% center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .landing-main .landing-encendamos {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding-top: clamp(1rem, 0.636rem + 1.818vw, 2rem);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing-main .landing-encendamos .texto {
    width: clamp(19rem, 12.091rem + 34.545vw, 38rem);
    height: clamp(11rem, 7.727rem + 16.364vw, 20rem);
    flex-shrink: 0;
    margin-left: clamp(0.1rem, -0.227rem + 1.636vw, 1rem);
  }
  .landing-main .landing-encendamos .texto p {
    width: 100%;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.636rem + 1.818vw, 2rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .landing-main .landing-encendamos .imagen {
    width: clamp(19rem, 10.636rem + 41.818vw, 42rem);
    height: clamp(18rem, 14.364rem + 18.182vw, 28rem);
    right: clamp(1rem, -10.25rem + 15vw, 7.75rem);
    bottom: clamp(1rem, -4.417rem + 7.222vw, 4.25rem);
    position: relative;
    margin-left: 1.5rem;
  }
  .landing-main .landing-colaboremos {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding-top: clamp(1rem, -7.125rem + 10.833vw, 5.875rem);
    margin-bottom: 3rem;
  }
  .landing-main .landing-colaboremos .titulo {
    margin-left: clamp(1rem, 0.273rem + 3.636vw, 3rem);
  }
  .landing-main .landing-colaboremos .titulo .text-1 {
    font-size: clamp(1.3rem, 0.573rem + 3.636vw, 3.3rem);
  }
  .landing-main .landing-colaboremos .video {
    overflow: hidden;
    width: 100%;
    height: clamp(13rem, 5rem + 40vw, 35rem);
  }
  .landing-main .landing-colaboremos .video video {
    width: 100%;
    margin-top: clamp(-17rem, -22rem + 6.667vw, -14rem);
    height: clamp(46rem, 37.855rem + 40.727vw, 68.4rem);
    background-position: center;
  }
  .landing-main .landing-colaboremos .parrafo {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .landing-main .landing-colaboremos .parrafo p {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.818rem + 0.909vw, 1.5rem);
    font-style: normal;
    font-weight: 400;
    width: clamp(19rem, 0.455rem + 92.727vw, 70rem);
  }
  .landing-main .landing-tarjetas {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    margin: 0;
    gap: 0.3rem;
    padding: 0;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba {
    display: flex;
    align-items: flex-end;
    gap: 0.3rem;
    width: 50%;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 {
    width: 90%;
    height: clamp(5rem, 16vw, 12.753rem);
    flex-shrink: 0;
    border-radius: 6.437px;
    border: 0.644px solid #fff;
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .contenido {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .contenido img {
    width: 1.3rem;
    height: auto;
    margin: 0 10px;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .contenido p {
    color: #fff;
    text-align: start;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 3.082vw, 2.695rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-1 .descripcion {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: clamp(8rem, 0.333rem + 38.336vw, 29.085rem);
    height: 63.722px;
    flex-shrink: 0;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 {
    width: 90%;
    height: clamp(5rem, 16vw, 12.753rem);
    flex-shrink: 0;
    border-radius: 6.437px;
    border: 0.644px solid #fff;
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .contenido {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .contenido img {
    width: 1.3rem;
    height: auto;
    margin: 0 10px;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .contenido p {
    color: #fff;
    text-align: start;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 3.082vw, 2.695rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-2 .descripcion {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: clamp(8rem, 0.333rem + 38.336vw, 29.085rem);
    height: 63.722px;
    flex-shrink: 0;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 {
    width: 90%;
    height: clamp(5rem, 16vw, 12.753rem);
    flex-shrink: 0;
    border-radius: 6.437px;
    border: 0.644px solid #fff;
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .contenido {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .contenido img {
    width: 1.3rem;
    height: auto;
    margin: 0 10px;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .contenido p {
    color: #fff;
    text-align: start;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 3.082vw, 2.695rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .landing-main .landing-tarjetas .tarjetas-arriba .tarjeta-3 .descripcion {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: clamp(8rem, 0.333rem + 38.336vw, 29.085rem);
    height: 63.722px;
    flex-shrink: 0;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3rem;
    width: 50%;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 {
    width: 90%;
    height: clamp(5rem, 16vw, 12.753rem);
    flex-shrink: 0;
    border-radius: 6.437px;
    border: 0.644px solid #fff;
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .contenido {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .contenido img {
    width: 1.3rem;
    height: auto;
    margin: 0 10px;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .contenido p {
    color: #fff;
    text-align: start;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 3.082vw, 2.695rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-4 .descripcion {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: clamp(8rem, 0.333rem + 38.336vw, 29.085rem);
    height: 63.722px;
    flex-shrink: 0;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 {
    width: 90%;
    height: clamp(5rem, 16vw, 12.753rem);
    flex-shrink: 0;
    border-radius: 6.437px;
    border: 0.644px solid #fff;
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .contenido {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .contenido img {
    width: 1.3rem;
    height: auto;
    margin: 0 10px;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .contenido p {
    color: #fff;
    text-align: start;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 3.082vw, 2.695rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-5 .descripcion {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: clamp(8rem, 0.333rem + 38.336vw, 29.085rem);
    height: 63.722px;
    flex-shrink: 0;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 {
    width: 90%;
    height: clamp(5rem, 16vw, 12.753rem);
    flex-shrink: 0;
    border-radius: 6.437px;
    border: 0.644px solid #fff;
    display: grid;
    grid-template-rows: 1.5fr 2fr;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .contenido {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .contenido img {
    width: 1.3rem;
    height: auto;
    margin: 0 10px;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .contenido p {
    color: #fff;
    text-align: start;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 3.082vw, 2.695rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .landing-main .landing-tarjetas .tarjetas-abajo .tarjeta-6 .descripcion {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: clamp(8rem, 0.333rem + 38.336vw, 29.085rem);
    height: 63.722px;
    flex-shrink: 0;
  }
  .landing-main .landing-fulltex {
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2rem;
  }
  .landing-main .landing-fulltex .texto-2024 {
    padding-left: clamp(2rem, 7vw, 5rem);
  }
  .landing-main .landing-fulltex .texto-2024 .text-1 {
    font-size: clamp(1.5rem, 5vw, 3.8rem);
  }
  .landing-main .landing-fulltex .texto-2024 .text-2 {
    font-size: clamp(1.5rem, 5vw, 3.8rem);
  }
  .landing-main .landing-fulltex .imagen-texto .div-img {
    aspect-ratio: 204/155;
    width: 50%;
    height: auto;
  }
  .landing-main .landing-fulltex .imagen-texto .div-p .titulo {
    font-size: clamp(1rem, 4vw, 2.5rem);
  }
  .landing-main .landing-fulltex .imagen-texto .div-p .parrafo {
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
  }
  .landing-main .landing-baires {
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2rem;
    margin-top: 0;
  }
  .landing-main .landing-baires .baires-imagen {
    width: 42%;
    aspect-ratio: 204/155;
    height: auto;
  }
  .landing-main .landing-baires .baires-texto .titulo {
    font-size: clamp(1rem, 4vw, 2.5rem);
  }
  .landing-main .landing-baires .baires-texto .parrafo {
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
  }
  .landing-main .landing-boscova {
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2rem;
    margin-top: 0;
  }
  .landing-main .landing-boscova .boscova-imagen {
    aspect-ratio: 204/155;
    height: auto;
  }
  .landing-main .landing-boscova .boscova-texto .titulo {
    font-size: clamp(1rem, 4vw, 2.5rem);
  }
  .landing-main .landing-boscova .boscova-texto .parrafo {
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
  }
  .landing-main .landing-ana {
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 2rem;
    margin-top: 0;
  }
  .landing-main .landing-ana .ana-imagen {
    aspect-ratio: 204/155;
    height: auto;
  }
  .landing-main .landing-ana .ana-texto .titulo {
    font-size: clamp(1rem, 4vw, 2.5rem);
  }
  .landing-main .landing-ana .ana-texto .parrafo {
    font-size: clamp(0.5rem, 1.7vw, 1.25rem);
  }
  .landing-main .landing-clientes .texto {
    padding-left: clamp(1rem, 10vw, 10rem);
  }
  .landing-main .landing-clientes .texto .text-1 {
    font-size: clamp(4rem, 7vw, 7rem);
    line-height: unset; /* 85.135% */
  }
  .landing-main .landing-clientes .texto .text-2 {
    font-size: clamp(4rem, 7vw, 7rem);
    line-height: unset; /* 85.135% */
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text {
    padding-top: 6rem;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .manitos img {
    width: clamp(2rem, 4vw, 4rem);
    height: auto;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .text-1 {
    height: 17rem !important;
    font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
    height: -moz-fit-content;
    height: fit-content;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas {
    position: absolute;
    bottom: 5%;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha img {
    aspect-ratio: 1/1;
    width: clamp(3rem, 5vw, 5.9rem);
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda img {
    aspect-ratio: 1/1;
    width: clamp(3rem, 5vw, 5.9rem);
  }
  .landing-main .landing-starter .starter-contenedor {
    width: clamp(19rem, 0.455rem + 92.727vw, 70rem);
    height: clamp(20rem, 10.583rem + 47.083vw, 34.125rem);
    background-image: url(../../img/landing/Rectangle\ 63.png) !important;
    background-position: center;
    background-size: cover;
  }
  .landing-main .landing-starter .starter-contenedor .starter-foco {
    right: clamp(0.8rem, -11.6rem + 24.8vw, 7rem) !important;
  }
  .landing-main .landing-starter .starter-contenedor .starter-foco img {
    width: 95.88px;
    height: 141px;
    flex-shrink: 0;
    margin-bottom: 30px;
  }
  .landing-main .landing-starter .starter-contenedor .starter-foco .text-1 {
    align-self: flex-end;
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: 40.6px;
    font-style: normal;
    font-weight: 400;
    line-height: 46.2px; /* 113.793% */
    margin-bottom: 18px;
  }
  .landing-main .landing-starter .starter-contenedor .starter-foco .text-2 {
    align-self: flex-end;
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: 65.8px;
    font-style: normal;
    font-weight: 400;
    line-height: 46.2px; /* 70.213% */
    margin-bottom: clamp(0.1rem, -1.95rem + 4.1vw, 1.125rem);
  }
  .landing-main .landing-starter .starter-contenedor .starter-foco .text-3 {
    align-self: flex-end;
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1rem, -1.125rem + 4.25vw, 2.063rem); /* 183.333% */
  }
  .landing-main .landing-starter .starter-contenedor .boton {
    right: 1rem !important;
    bottom: 1rem !important;
  }
  .landing-main .starter-mobile {
    display: none !important;
  }
  .landing-main .landing-planes {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding-top: 0;
  }
  .landing-main .landing-planes .planes-texto {
    width: -moz-max-content;
    width: max-content;
    margin-left: clamp(1rem, -1.932rem + 14.659vw, 9.063rem);
  }
  .landing-main .landing-planes .planes-texto .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(3rem, 1.614rem + 6.932vw, 6.813rem);
    font-style: normal;
    font-weight: 600;
    line-height: clamp(2.5rem, 1.318rem + 5.909vw, 5.75rem); /* 84.404% */
  }
  .landing-main .landing-planes .planes-texto .text-2 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(2.5rem, 0.932rem + 7.842vw, 6.813rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(2.5rem, 1.318rem + 5.909vw, 5.75rem);
  }
  .landing-main .landing-planes .planes-tarjetas {
    width: 100%;
    height: 38rem;
    display: flex;
    margin-top: clamp(2rem, -0.727rem + 13.636vw, 9.5rem);
    margin-bottom: clamp(2.188rem, -0.767rem + 14.773vw, 10.313rem);
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 {
    border-radius: 3px;
    width: clamp(19rem, 13.5rem + 27.5vw, 34.125rem);
    height: clamp(22rem, 17.591rem + 22.045vw, 34.125rem);
    flex-shrink: 0;
    background-image: url(../../img/landing/FondoPlanBasic.png);
    background-position: center;
    padding-top: clamp(1rem, -2.229rem + 4.306vw, 2.938rem);
    padding-left: 0.625rem;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .arriba .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(3rem, 2.636rem + 1.818vw, 4rem);
    font-style: normal;
    font-weight: 500;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .arriba .text-2 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.8rem, 1.727rem + 0.364vw, 2rem);
    font-style: normal;
    font-weight: 300;
    line-height: 10px;
    margin-bottom: clamp(1.5rem, 1.159rem + 1.705vw, 2.438rem);
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .arriba .text-3 {
    width: 100%;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 0.655rem + 0.727vw, 1.2rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1rem, 0.614rem + 1.932vw, 2.063rem);
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo {
    display: flex;
    flex-direction: column;
    padding-right: clamp(0.2rem, -0.477rem + 3.386vw, 2.063rem);
    align-items: end;
    margin-top: clamp(2rem, -2.688rem + 6.25vw, 4.813rem);
    gap: 1.6rem;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios {
    display: flex;
    align-items: center;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original {
    align-self: flex-end;
    margin-right: clamp(1rem, -0.544rem + 2.058vw, 1.926rem);
    position: relative;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original .text-1 {
    color: rgba(255, 255, 255, 0.363);
    font-family: NohemiRegular;
    font-size: clamp(0.9rem, 0.834rem + 0.331vw, 1.082rem);
    font-style: normal;
    font-weight: 400;
    line-height: 19.704px; /* 113.793% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original .text-2 {
    color: rgba(235, 86, 0, 0.3294117647);
    font-family: NohemiRegular;
    font-size: clamp(1.2rem, 0.999rem + 1.007vw, 1.754rem);
    font-style: normal;
    font-weight: 400;
    line-height: 19.704px; /* 70.213% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .original .linea {
    width: 101%;
    height: 2px;
    top: 60%;
    border-color: rgba(105, 105, 105, 0.281);
    background-color: rgb(124, 124, 124);
    position: absolute;
    z-index: 1000;
    border-radius: 2px;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .descuento .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.4rem, 1.25rem + 0.75vw, 1.813rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 113.793% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .precios .descuento .text-2 {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(2.3rem, 2.068rem + 1.159vw, 2.938rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 70.213% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-1 .abajo .frase {
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.7rem, 0.591rem + 0.545vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 183.333% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 {
    width: clamp(19rem, 13.5rem + 27.5vw, 34.125rem);
    height: clamp(22rem, 17.591rem + 22.045vw, 34.125rem);
    flex-shrink: 0;
    background-image: url(../../img/landing/FondoPlanPro.png);
    background-position: center;
    padding-top: clamp(1rem, -2.229rem + 4.306vw, 2.938rem);
    padding-left: 0.625rem;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .arriba .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(3rem, 2.636rem + 1.818vw, 4rem);
    font-style: normal;
    font-weight: 500;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .arriba .text-2 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.8rem, 1.727rem + 0.364vw, 2rem);
    font-style: normal;
    font-weight: 300;
    line-height: 10px;
    margin-bottom: clamp(1.5rem, 1.159rem + 1.705vw, 2.438rem);
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .arriba .text-3 {
    width: 100%;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 0.655rem + 0.727vw, 1.2rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1rem, 0.614rem + 1.932vw, 2.063rem);
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo {
    display: flex;
    flex-direction: column;
    padding-right: clamp(0.2rem, -0.477rem + 3.386vw, 2.063rem);
    align-items: end;
    margin-top: clamp(2rem, -2.688rem + 6.25vw, 4.813rem);
    gap: 1.6rem;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios {
    display: flex;
    align-items: center;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original {
    align-self: flex-end;
    margin-right: clamp(1rem, -0.544rem + 2.058vw, 1.926rem);
    position: relative;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original .text-1 {
    color: rgba(255, 255, 255, 0.363);
    font-family: NohemiRegular;
    font-size: clamp(0.9rem, 0.834rem + 0.331vw, 1.082rem);
    font-style: normal;
    font-weight: 400;
    line-height: 19.704px; /* 113.793% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original .text-2 {
    color: rgba(235, 86, 0, 0.3294117647);
    font-family: NohemiRegular;
    font-size: clamp(1.2rem, 0.999rem + 1.007vw, 1.754rem);
    font-style: normal;
    font-weight: 400;
    line-height: 19.704px; /* 70.213% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .original .linea {
    width: 101%;
    height: 2px;
    top: 60%;
    border-color: rgba(105, 105, 105, 0.281);
    background-color: rgb(124, 124, 124);
    position: absolute;
    z-index: 1000;
    border-radius: 2px;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .descuento .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.4rem, 1.25rem + 0.75vw, 1.813rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 113.793% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .precios .descuento .text-2 {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(2.3rem, 2.068rem + 1.159vw, 2.938rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 70.213% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-2 .abajo .frase {
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.7rem, 0.591rem + 0.545vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 183.333% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 {
    width: clamp(19rem, 13.5rem + 27.5vw, 34.125rem);
    height: clamp(22rem, 17.591rem + 22.045vw, 34.125rem);
    flex-shrink: 0;
    background-image: url(../../img/landing/FondoPlanBusiness.png);
    background-position: center;
    padding-top: clamp(1rem, -2.229rem + 4.306vw, 2.938rem);
    padding-left: 0.625rem;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .arriba .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(3rem, 2.636rem + 1.818vw, 4rem);
    font-style: normal;
    font-weight: 500;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .arriba .text-2 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.8rem, 1.727rem + 0.364vw, 2rem);
    font-style: normal;
    font-weight: 300;
    line-height: 10px;
    margin-bottom: clamp(1.5rem, 1.159rem + 1.705vw, 2.438rem);
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .arriba .text-3 {
    width: 100%;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.8rem, 0.655rem + 0.727vw, 1.2rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1rem, 0.614rem + 1.932vw, 2.063rem);
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo {
    display: flex;
    flex-direction: column;
    padding-right: clamp(0.2rem, -0.477rem + 3.386vw, 2.063rem);
    align-items: end;
    margin-top: clamp(2rem, -2.688rem + 6.25vw, 4.813rem);
    gap: 1.6rem;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios {
    display: flex;
    align-items: center;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original {
    align-self: flex-end;
    margin-right: clamp(1rem, -0.544rem + 2.058vw, 1.926rem);
    position: relative;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original .text-1 {
    color: rgba(255, 255, 255, 0.363);
    font-family: NohemiRegular;
    font-size: clamp(0.9rem, 0.834rem + 0.331vw, 1.082rem);
    font-style: normal;
    font-weight: 400;
    line-height: 19.704px; /* 113.793% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original .text-2 {
    color: rgba(235, 86, 0, 0.3294117647);
    font-family: NohemiRegular;
    font-size: clamp(1.2rem, 0.999rem + 1.007vw, 1.754rem);
    font-style: normal;
    font-weight: 400;
    line-height: 19.704px; /* 70.213% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .original .linea {
    width: 101%;
    height: 2px;
    top: 60%;
    border-color: rgba(105, 105, 105, 0.281);
    background-color: rgb(124, 124, 124);
    position: absolute;
    z-index: 1000;
    border-radius: 2px;
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .descuento .text-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.4rem, 1.25rem + 0.75vw, 1.813rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 113.793% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .precios .descuento .text-2 {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(2.3rem, 2.068rem + 1.159vw, 2.938rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 70.213% */
  }
  .landing-main .landing-planes .planes-tarjetas .plan-3 .abajo .frase {
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.7rem, 0.591rem + 0.545vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 183.333% */
  }
  .landing-main .pregunas-frecuentes .text-img .text-1 {
    font-size: clamp(4rem, 7vw, 8rem);
    line-height: clamp(4rem, 6vw, 7rem); /* 88.372% */
  }
  .landing-main .pregunas-frecuentes .text-img .text-2 {
    font-size: clamp(4rem, 7vw, 8rem);
    line-height: clamp(4rem, 6vw, 7rem); /* 88.372% */
  }
  .landing-main .pregunas-frecuentes .text-img .video-contenedor {
    width: clamp(20rem, 47vw, 50rem);
    height: auto;
    aspect-ratio: 788/484;
  }
  .landing-main .pregunas-frecuentes .text-img .video-contenedor video {
    bottom: clamp(-2rem, -5vw, -5rem);
    width: 150%;
    height: auto !important;
  }
  .landing-main .final {
    height: unset;
  }
  .landing-main .final .gmail p {
    font-size: clamp(1.3rem, 0.56rem + 3.7vw, 5rem) !important;
  }
  .landing-main .final .node-contenedor {
    margin-top: 4rem;
    padding-left: clamp(4rem, -1.521rem + 7.361vw, 7.313rem);
    padding-right: clamp(4rem, -1.521rem + 7.361vw, 7.313rem);
    flex-direction: column;
  }
  .landing-main .final .node-contenedor .div-img img {
    padding-left: 0;
  }
  .landing-main .final .node-contenedor .div-derecha {
    width: 100%;
    padding-top: 2rem;
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha {
    gap: 1rem;
  }
  .landing-main .final .derechos-reservados {
    bottom: unset;
    position: relative;
    padding: 4rem;
  }
}
@media (max-width: 1000px) {
  .landing-main .mantenimiento-formulario .formulario-borde {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba {
    flex-direction: column;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding: 1.19rem 1rem;
    border-right: none;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 {
    font-size: 2.109rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 span {
    font-size: 3.13344rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-2 {
    width: 19.75rem;
    font-size: 1rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding: 1.19rem 1rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-1 {
    font-size: 1rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-2 {
    width: 100%;
    text-align: center;
    font-size: 1.375rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba {
    width: 100%;
    flex-direction: column;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda {
    width: 100%;
    border-right: none;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre {
    width: 100%;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-1 {
    font-size: 1rem;
    opacity: 0.38;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 {
    font-size: 0.625rem;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 span {
    color: #eb5500;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre input {
    font-size: 1rem !important;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .texto-1 {
    width: 17.5625rem;
    font-size: 1rem;
    opacity: 0.38;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo {
    flex-direction: column;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda {
    width: 100%;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre {
    width: 100%;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-1 {
    color: #7c7c7c;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-2 span {
    color: #eb5500;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha {
    width: 100%;
  }
  .landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .texto-1 {
    width: 17.5625rem;
    font-size: 1rem;
    opacity: 0.38;
  }
  .landing-main .mantenimiento-formulario .botones-checkpoints {
    width: 100%;
    margin-top: 1.25rem;
  }
  .landing-main .mantenimiento-formulario .botones-checkpoints .izquierda {
    flex-direction: column;
  }
  .landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba {
    display: none;
  }
  .landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton {
    padding-right: 0.5rem;
    padding-top: 0.44rem;
    padding-left: 8.5rem;
  }
  .landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton .boton {
    font-size: 1.16463rem;
  }
}
@media (max-width: 800px) {
  .landing-main .landing-barra {
    display: none;
  }
  .landing-main .landing-barra-mobile {
    display: block;
  }
  .landing-main .landing-clientes {
    padding-bottom: 0;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta {
    flex-direction: column;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-img-contenedor {
    width: 100%;
    aspect-ratio: 429/300;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text {
    width: 100%;
    display: grid;
    grid-template-rows: 3fr 1fr 1fr;
    grid-template-columns: 1fr 5fr 1fr;
    margin: 0;
    padding: 0 2rem;
    padding-top: 1rem;
    justify-content: center;
    text-align: center;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .carita {
    position: relative;
    grid-row: 2;
    align-self: flex-start;
    margin: auto 0;
    top: unset;
    left: unset;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .manitos {
    grid-row: 2;
    grid-column: 3;
    align-self: flex-end;
    margin: auto;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .text-1 {
    grid-row: 1;
    grid-column: span 3;
    width: 100%;
    line-height: 1.8rem;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas {
    grid-row: 3;
    flex-direction: row;
    align-self: center;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    position: relative;
    grid-column: span 3;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha {
    grid-column: 3;
    grid-row: 1;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha img {
    width: clamp(3rem, 10vw, 5.9rem);
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda {
    grid-column: 1;
    grid-row: 1;
  }
  .landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda img {
    width: clamp(3rem, 10vw, 5.9rem);
  }
  .landing-main .pregunas-frecuentes {
    flex-direction: column;
  }
  .landing-main .pregunas-frecuentes .text-img {
    width: 100%;
  }
  .landing-main .pregunas-frecuentes .text-img .text-1 {
    font-size: clamp(2rem, 12vw, 6rem);
    line-height: clamp(1.5rem, 10vw, 5rem); /* 88.372% */
    padding-left: 1rem;
  }
  .landing-main .pregunas-frecuentes .text-img .text-2 {
    font-size: clamp(2rem, 12vw, 6rem);
    line-height: clamp(1.5rem, 10vw, 5rem); /* 88.372% */
    padding-left: 1rem;
  }
  .landing-main .pregunas-frecuentes .text-img .video-contenedor {
    width: 100%;
    height: auto;
    aspect-ratio: 788/484;
  }
  .landing-main .pregunas-frecuentes .text-img .video-contenedor video {
    left: -20%;
    bottom: clamp(-2rem, -5vw, -5rem);
    width: 150%;
    height: auto !important;
  }
  .landing-main .pregunas-frecuentes .preguntas-contenedor {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .landing-main .final {
    height: unset;
  }
  .landing-main .final .gmail {
    text-align: left;
  }
  .landing-main .final .gmail p {
    padding-left: clamp(1rem, -1.521rem + 7.361vw, 7.313rem);
    padding-right: clamp(1.042rem, -1.521rem + 7.361vw, 7.313rem);
    font-size: clamp(1rem, 6.5vw, 5rem);
    line-height: unset;
  }
  .landing-main .final .node-contenedor {
    margin-top: 1rem;
    padding-left: clamp(1rem, -1.521rem + 7.361vw, 7.313rem);
    padding-right: clamp(1.042rem, -1.521rem + 7.361vw, 7.313rem);
  }
  .landing-main .final .node-contenedor .div-img img {
    width: clamp(5rem, 25vw, 13.5rem);
    height: auto;
  }
  .landing-main .final .node-contenedor .div-img .text {
    width: clamp(12rem, 60vw, 35rem);
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 2vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 21px;
  }
  .landing-main .final .node-contenedor .div-derecha {
    width: 100%;
    padding-top: 2rem;
  }
  .landing-main .final .node-contenedor .div-derecha .servicios {
    align-items: center;
  }
  .landing-main .final .node-contenedor .div-derecha .servicios .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .servicios .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .servicios .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
  }
  .landing-main .final .node-contenedor .div-derecha .servicios .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades {
    padding-left: 0;
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .izquierda {
    margin-right: 0;
    grid-column: 2;
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-5 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha {
    grid-column: 3;
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-5 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .planes {
    grid-column: 4;
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-5 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .landing-main .final .derechos-reservados {
    padding: 4rem clamp(1rem, -1.521rem + 7.361vw, 7.313rem);
  }
  .landing-main .final .derechos-reservados p {
    font-size: clamp(0.55rem, 2vw, 1.25rem);
  }
  .landing-main .final .derechos-reservados .redes div img {
    width: clamp(0.6rem, 2.2vw, 1.4rem);
    height: clamp(0.6rem, 2.2vw, 1.4rem);
  }
  .landing-main .starter-mobile {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .landing-main .starter-mobile .starter-contenedor {
    width: clamp(19rem, 0.455rem + 92.727vw, 70rem);
    height: clamp(20rem, 10.583rem + 47.083vw, 34.125rem);
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    background-color: white;
    background-image: url(../../img/landing/Rectangle\ 63.png);
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .landing-main .starter-mobile .starter-contenedor .starter-textos {
    padding-top: clamp(1rem, -0.136rem + 5.682vw, 4.125rem);
    padding-left: clamp(0.3rem, 0.114rem + 0.932vw, 0.813rem);
    position: relative;
  }
  .landing-main .starter-mobile .starter-contenedor .starter-textos .text-1 {
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: clamp(2.8rem, 1.341rem + 7.295vw, 6.813rem) !important;
    font-style: normal;
    font-weight: 500;
    line-height: clamp(2rem, 1.068rem + 4.659vw, 4.563rem);
  }
  .landing-main .starter-mobile .starter-contenedor .starter-textos .text-2 {
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: clamp(2rem, 1.042rem + 4.792vw, 3.438rem);
    font-style: normal;
    font-weight: 300;
    line-height: clamp(2.5rem, 2.25rem + 1.25vw, 3.188rem); /* 167.273% */
  }
  .landing-main .starter-mobile .starter-contenedor .starter-textos .text-2 span {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(1.5rem, 1.355rem + 0.727vw, 1.9rem) !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* 70.213% */
  }
  .landing-main .starter-mobile .starter-contenedor .starter-textos .text-4 {
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.705rem + 1.477vw, 1.813rem) !important;
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1.3rem, 1.023rem + 1.386vw, 2.063rem);
    width: 100% !important;
    margin-top: 1rem;
  }
  .landing-main .starter-mobile .starter-contenedor .starter-foco {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-left: clamp(0.3rem, 0.114rem + 0.932vw, 0.813rem);
    padding-top: clamp(0.1rem, -1.167rem + 6.333vw, 2rem);
    padding-right: clamp(0.3rem, -1.5rem + 9vw, 3rem);
    gap: clamp(0rem, -3.333rem + 16.667vw, 5rem);
  }
  .landing-main .starter-mobile .starter-contenedor .starter-foco p {
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: clamp(0.625rem, 0.242rem + 1.917vw, 1.2rem);
    font-style: normal;
    font-weight: 400;
    line-height: 33px; /* 330% */
  }
  .landing-main .starter-mobile .starter-contenedor .starter-foco .foquito-contenedor {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .landing-main .starter-mobile .starter-contenedor .starter-foco .foquito-contenedor img {
    width: clamp(1.1rem, -0.833rem + 9.667vw, 4rem);
  }
  .landing-main .starter-mobile .starter-contenedor .starter-foco .foquito-contenedor p {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(2rem, 0rem + 10vw, 5rem);
    font-style: normal;
    font-weight: 400;
    line-height: 46.2px; /* 70.213% */
  }
  .landing-main .landing-starter {
    display: none !important;
  }
  .landing-main .landing-mantenimiento {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: clamp(2rem, 0.409rem + 7.955vw, 6.375rem);
    position: relative;
  }
  .landing-main .landing-mantenimiento .mantenimiento-contenedor {
    width: clamp(19rem, 0.455rem + 92.727vw, 70rem);
    height: clamp(20rem, 14.864rem + 25.682vw, 34.125rem);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-image: url(../../img/landing/banerempresarial.png);
    background-position: center;
    background-size: cover;
  }
  .landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos {
    padding-top: clamp(0.8rem, -0.409rem + 6.045vw, 4.125rem);
    padding-left: clamp(0.3rem, 0.114rem + 0.932vw, 0.813rem);
    position: relative;
  }
  .landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-1 {
    font-size: clamp(2.4rem, 1.909rem + 1.455vw, 3rem);
    line-height: clamp(1.9rem, 0.932rem + 4.841vw, 4.563rem);
  }
  .landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-2 {
    display: none;
  }
  .landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-3 {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(2.4rem, 1.964rem + 2.182vw, 3.6rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(3rem, 2.5rem + 2.5vw, 4.375rem); /* 70.213% */
    margin-bottom: clamp(0.4rem, 0.318rem + 0.409vw, 0.625rem);
  }
  .landing-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-4 {
    color: #fdf9f9;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.704rem + 1.478vw, 1.813rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1.4rem, 1.159rem + 1.205vw, 2.063rem); /* 113.793% */
    width: clamp(18rem, 11.25rem + 33.75vw, 36.563rem);
  }
  .landing-main .landing-mantenimiento .boton {
    display: none;
  }
}/*# sourceMappingURL=landing.css.map */