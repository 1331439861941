.mantenimiento-landing-main .intro {
  width: 100%;
  height: 100vh;
  position: relative;
}
.mantenimiento-landing-main .intro video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.mantenimiento-landing-main .intro .soluciones-responsive {
  display: none;
}
.mantenimiento-landing-main .intro .mascara-responsive {
  display: none;
}
.mantenimiento-landing-main .soluciones-main {
  width: 100%;
  height: clamp(55rem, 35rem + 25vw, 65rem);
  display: flex;
  background: #0e0e0e;
  position: relative;
  padding-left: clamp(2rem, -12rem + 17.5vw, 9rem);
  padding-top: 11.69rem;
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos {
  z-index: 2;
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3rem + 5vw, 9rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos .texto-1 span {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3rem + 5vw, 9rem);
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 104%; /* 2.08rem */
  margin-top: clamp(-1rem, 0.2rem - 1.5vw, -1.6rem);
  margin-bottom: 1.65rem;
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos .texto-3 {
  width: clamp(47rem, 42.25rem + 5.938vw, 49.375rem);
  color: #fff;
  font-family: NohemiRegular;
  font-size: 2rem;
  font-style: normal;
  font-weight: 200;
  line-height: 104%; /* 2.08rem */
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos .boton {
  display: flex;
  width: clamp(19rem, 12.229rem + 9.028vw, 23.063rem);
  height: clamp(3rem, 1.73rem + 1.694vw, 3.762rem);
  align-items: center;
  justify-content: center;
  gap: 27.69px;
  flex-shrink: 0;
  border-radius: 6.02px;
  background: #eb5500;
  margin-top: 0.5rem;
}
.mantenimiento-landing-main .soluciones-main .soluciones-textos .boton p {
  padding-top: 6px;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.8rem, 0.85rem + 1.267vw, 2.37rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .soluciones-main .video-soluciones-contenedor {
  width: clamp(40rem, 29.375rem + 13.281vw, 45.313rem);
  height: 51.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
  top: 4.12rem;
  right: clamp(1rem, -15.26rem + 20.325vw, 9.13rem);
  z-index: 1;
  mix-blend-mode: lighten;
}
.mantenimiento-landing-main .soluciones-main .video-soluciones-contenedor video {
  height: 100%;
  mix-blend-mode: lighten;
}
.mantenimiento-landing-main .soluciones-main .soluciones-responsive {
  display: none;
}
.mantenimiento-landing-main .importancia-mantenimiento {
  width: 100%;
  height: 65rem;
  background: #0e0e0e;
  position: relative;
}
.mantenimiento-landing-main .importancia-mantenimiento .mascara-blanca {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 31.78125rem 0rem 0rem 0rem;
  position: absolute;
  background-color: rgb(255, 255, 255);
}
.mantenimiento-landing-main .importancia-mantenimiento video {
  position: absolute;
  z-index: 2;
  border-radius: 31.78125rem 0rem 0rem 0rem;
  mix-blend-mode: exclusion;
  width: clamp(48rem, 23.625rem + 30.469vw, 60.188rem);
  height: 47.75rem;
  top: clamp(4rem, -7.62rem + 14.525vw, 9.81rem);
  left: clamp(1rem, -12.24rem + 16.55vw, 7.62rem);
}
.mantenimiento-landing-main .importancia-mantenimiento img {
  position: absolute;
  z-index: 2;
  right: clamp(2rem, -10.3rem + 15.375vw, 8.15rem);
  bottom: 0;
}
.mantenimiento-landing-main .importancia-mantenimiento .arriba-texto {
  top: 5rem;
  right: clamp(3rem, -27.76rem + 38.45vw, 18.38rem);
  position: absolute;
  z-index: 2;
}
.mantenimiento-landing-main .importancia-mantenimiento .arriba-texto .texto-1 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 3.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .importancia-mantenimiento .arriba-texto .texto-2 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 3.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: -1rem;
}
.mantenimiento-landing-main .importancia-mantenimiento .arriba-texto .texto-2 span {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 3.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.mantenimiento-landing-main .importancia-mantenimiento .abajo-texto {
  position: absolute;
  width: clamp(40rem, 20rem + 25vw, 50rem);
  height: 29.75rem;
  z-index: 2;
  top: 25.25rem;
  right: clamp(1rem, -15.26rem + 20.325vw, 9.13rem);
}
.mantenimiento-landing-main .importancia-mantenimiento .abajo-texto p {
  color: #000;
  font-family: NohemiRegular;
  font-size: clamp(2.5rem, 1.5rem + 1.25vw, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 3.3rem */
}
.mantenimiento-landing-main .ventajas-contenedor {
  width: 100%;
  height: 78.81rem;
  background: rgb(255, 255, 255);
  padding-top: 4.19rem;
}
.mantenimiento-landing-main .ventajas-contenedor .mascara-blanca {
  display: none;
}
.mantenimiento-landing-main .ventajas-contenedor .arriba-texto {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 5rem;
}
.mantenimiento-landing-main .ventajas-contenedor .arriba-texto .texto-1 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 3.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: -1rem;
  margin-bottom: 1.44rem;
}
.mantenimiento-landing-main .ventajas-contenedor .arriba-texto .texto-2 {
  width: clamp(70rem, 10.375rem + 74.531vw, 99.813rem);
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: clamp(1.5rem, 0.5rem + 1.25vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem; /* 0rem */
}
.mantenimiento-landing-main .ventajas-contenedor .abajo-video {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5.69rem;
}
.mantenimiento-landing-main .ventajas-contenedor .abajo-video video {
  height: 58.75rem;
  flex-shrink: 0;
}
.mantenimiento-landing-main .servicios-mantenimiento-main {
  background-color: #fff;
  width: 100%;
  height: 58.0625rem;
  padding-top: 1.13rem;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: clamp(2rem, -12.88rem + 18.6vw, 9.44rem);
}
.mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto .texto-1 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 3.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115.573%; /* 4.55069rem */
  margin-left: 1rem;
  margin-bottom: 1.44rem;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto .texto-2 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: clamp(1.6rem, 0.8rem + 1vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* 0rem */
  width: clamp(70rem, 10.375rem + 74.531vw, 99.813rem);
}
.mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto .texto-2 span {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0%;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 102px;
  position: relative;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor {
  width: clamp(70rem, -0.313rem + 93.75vw, 112.188rem);
  height: 546px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-image: url(../../img/landing/banerempresarial.png);
  background-position: center;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos {
  padding-top: 66px;
  padding-left: 13px;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-1 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(3rem, -0.854rem + 5.139vw, 5.313rem);
  font-style: normal;
  font-weight: 500;
  line-height: 4.5625rem;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-2 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(2.8rem, 1.737rem + 1.417vw, 3.438rem);
  font-style: normal;
  font-weight: 300;
  line-height: 51px; /* 167.273% */
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-3 {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: clamp(3.6rem, 2.746rem + 1.139vw, 4.112rem);
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 70.213% */
  margin-bottom: 10px;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-4 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(1.5rem, 0.979rem + 0.694vw, 1.813rem);
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 113.793% */
  width: 585px;
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .boton {
  width: 545.133px;
  height: 74px;
  display: flex;
  padding: 20.045px 20.788px;
  justify-content: center;
  align-items: center;
  gap: 34.151px;
  flex-shrink: 0;
  border-radius: 7.424px;
  border: 1.233px solid #eb5500;
  position: absolute;
  bottom: 3.25rem;
  right: clamp(4rem, -54.378rem + 77.838vw, 39.027rem);
}
.mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .boton a p {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: 46.772px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .fulltex-mantenimiento-main {
  width: 100%;
  height: 50.5rem;
  display: flex;
  background-color: white;
  padding-top: 2.19rem;
  gap: clamp(2rem, -3.62rem + 7.025vw, 4.81rem);
  padding-left: clamp(2rem, -11.5rem + 16.875vw, 8.75rem);
}
.mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto .texto-1 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 115.573%; /* 2.45594rem */
  width: clamp(40rem, -5.5rem + 56.875vw, 62.75rem);
  margin-bottom: 6.13rem;
}
.mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto .texto-2 {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal; /* 2.31144rem */
  text-align: end;
  margin-bottom: 6.13rem;
}
.mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto .texto-2 span {
  color: #0e0e0e;
  font-family: NohemiRegular;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal; /* 1.58913rem */
}
.mantenimiento-landing-main .fulltex-mantenimiento-main .derecha-imagen {
  width: clamp(35rem, 28.75rem + 7.813vw, 38.125rem);
  height: 42.75rem;
  flex-shrink: 0;
  background-image: url(../../videos/mantenimiento/FT.jfif);
  background-size: cover;
  background-position: center;
  border-radius: 0.3125rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.mantenimiento-landing-main .mantenimiento-formulario {
  width: 100%;
  height: 77.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 3rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde {
  width: clamp(79rem, 12.75rem + 82.813vw, 112.125rem);
  height: 54.9375rem;
  flex-shrink: 0;
  border: 1px solid #fff;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba {
  display: flex;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda {
  width: clamp(42rem, -2rem + 55vw, 64rem);
  height: clamp(12rem, 3.06rem + 11.175vw, 16.47rem);
  display: flex;
  flex-direction: column;
  padding: 1.44rem 5.81rem;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, -2.75rem + 5.938vw, 4.375rem);
  font-style: normal;
  font-weight: 500;
  line-height: 115.573%; /* 5.05631rem */
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 span {
  color: #eb5500;
  font-family: NohemiRegular;
  font-size: clamp(4rem, -1rem + 6.25vw, 6.5rem);
  font-style: normal;
  font-weight: 700;
  line-height: 115.573%;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.5rem, 0.25rem + 1.563vw, 2.125rem);
  font-style: normal;
  font-weight: 500;
  line-height: 115.573%; /* 2.45594rem */
  width: clamp(35rem, 5.5rem + 36.875vw, 49.75rem);
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha {
  width: -moz-fit-content;
  width: fit-content;
  height: clamp(12rem, 3.06rem + 11.175vw, 16.47rem);
  padding: 3.88rem 3.69rem;
  border-bottom: 1px solid #fff;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.1rem, -0.2rem + 1.625vw, 1.75rem);
  font-style: normal;
  font-weight: 300;
  line-height: 115.573%; /* 2.0225rem */
  margin-bottom: clamp(1.1rem, -1.82rem + 3.65vw, 2.56rem);
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 115.573%; /* 2.0225rem */
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba {
  display: flex;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda {
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25rem 1.31rem;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre {
  width: clamp(35rem, -7.12rem + 52.65vw, 56.06rem);
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.44rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 span {
  color: #eb5500;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, 0.125rem + 2.344vw, 2.938rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda input:-internal-autofill-selected {
  background-color: red !important;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha {
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 1.25rem 1.31rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .arriba-nombre {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1.31rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, 0.125rem + 2.344vw, 2.938rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo {
  display: flex;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda {
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25rem 1.31rem;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre {
  display: flex;
  width: clamp(35rem, -7.12rem + 52.65vw, 56.06rem);
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.44rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-2 span {
  color: #eb5500;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, 0.125rem + 2.344vw, 2.938rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha {
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 1.25rem 1.31rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .arriba-nombre {
  display: flex;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1.31rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .arriba-nombre .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .arriba-nombre .texto-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: none;
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2rem, 0.125rem + 2.344vw, 2.938rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .abajo {
  width: 100%;
  height: 19.37rem;
  padding: 1.25rem 1.31rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .abajo .texto-1 {
  margin-bottom: 1.31rem;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .abajo textarea {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.021);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #fff;
  resize: none;
  font-family: NohemiRegular;
  font-size: 2.4375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  padding: 0.5rem;
  overflow-y: scroll;
  border: none;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints {
  width: clamp(79rem, 12.75rem + 82.813vw, 112.125rem);
  height: -moz-max-content;
  height: max-content;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 2.94rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .izquierda {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.56rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-1 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-1 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 0.2rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-2 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-2 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-3 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-3 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-4 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-4 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-5 {
  padding: clamp(0.8rem, -0.34rem + 1.425vw, 1.37rem) 0;
  width: clamp(10rem, 2rem + 10vw, 14rem);
  border: 1px solid #fff;
  text-align: center;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba .check-5 button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton {
  text-align: end;
  border: white 1px solid;
  padding-right: 2.69rem;
  padding-top: 0.38rem;
}
.mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton button {
  all: unset;
  color: #fff;
  font-family: NohemiRegular;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .pregunas-frecuentes {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.mantenimiento-landing-main .pregunas-frecuentes .text-img {
  height: 100%;
  padding-top: clamp(2rem, -3rem + 6.667vw, 5rem);
}
.mantenimiento-landing-main .pregunas-frecuentes .text-img .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(6rem, 2.563rem + 4.583vw, 8.063rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(5.8rem, 3.592rem + 2.944vw, 7.125rem); /* 88.372% */
}
.mantenimiento-landing-main .pregunas-frecuentes .text-img .text-2 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(6rem, 2.563rem + 4.583vw, 8.063rem);
  font-style: normal;
  font-weight: 600;
  line-height: clamp(5.8rem, 3.592rem + 2.944vw, 7.125rem);
}
.mantenimiento-landing-main .pregunas-frecuentes .text-img .video-contenedor {
  width: clamp(40rem, 23.333rem + 22.222vw, 50rem);
  height: clamp(28rem, 24.25rem + 5vw, 30.25rem);
  overflow: hidden;
  position: relative;
}
.mantenimiento-landing-main .pregunas-frecuentes .text-img .video-contenedor video {
  position: absolute;
  left: -5rem;
  bottom: -5rem;
  width: clamp(54rem, 45.042rem + 11.944vw, 59.375rem);
  height: auto !important;
}
.mantenimiento-landing-main .pregunas-frecuentes .preguntas-contenedor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}
.mantenimiento-landing-main .proyectos-contenedor {
  display: block;
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  position: relative;
}
.mantenimiento-landing-main .proyectos-contenedor .titulo {
  display: flex;
  padding-top: 3.56rem;
  padding-left: clamp(1rem, -7.62rem + 10.775vw, 5.31rem);
  margin-bottom: clamp(5rem, -4.6rem + 12vw, 9.8rem);
}
.mantenimiento-landing-main .proyectos-contenedor .titulo .texto-1 {
  color: #fdf9f9;
  font-family: NohemiRegular;
  font-size: clamp(4rem, 1.375rem + 3.281vw, 5.313rem);
  font-style: normal;
  font-weight: 500;
  line-height: 5.75rem; /* 108.235% */
}
.mantenimiento-landing-main .proyectos-contenedor .titulo img {
  margin-left: -5rem;
}
.mantenimiento-landing-main .proyectos-contenedor .tarjeta-texto {
  height: 13rem;
  padding-left: clamp(1rem, -15rem + 20vw, 9rem);
}
.mantenimiento-landing-main .proyectos-contenedor .tarjeta-texto .texto-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(2.5rem, 0.75rem + 2.188vw, 3.375rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mantenimiento-landing-main .proyectos-contenedor .tarjeta-texto .texto-2 {
  width: clamp(45rem, 33rem + 15vw, 51rem);
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1.3rem, 0.65rem + 0.812vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.mantenimiento-landing-main .proyectos-contenedor .ver-proyecto-contenedor {
  padding-left: clamp(34rem, 4.12rem + 37.35vw, 48.94rem);
  margin-top: 0.75rem;
}
.mantenimiento-landing-main .proyectos-contenedor .img-tarjetas {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding-top: 0.94rem;
  margin-right: clamp(1.5rem, -6.12rem + 9.525vw, 5.31rem);
}
.mantenimiento-landing-main .proyectos-contenedor .img-tarjetas .tarjetita {
  width: clamp(18rem, 7.125rem + 13.594vw, 23.438rem);
  height: clamp(14rem, 6.483rem + 9.396vw, 17.758rem);
  flex-shrink: 0;
  background-color: #eb5500;
  border-radius: 0.24725rem;
}
.mantenimiento-landing-main .proyectos-contenedor .img-tarjetas .img-1 {
  background-image: url(../../img/landing/Fulltex.png);
  background-position: center;
  background-size: cover;
}
.mantenimiento-landing-main .proyectos-contenedor .img-tarjetas .img-2 {
  background-image: url(../../img/landing/Baires.png);
  background-position: center;
  background-size: cover;
}
.mantenimiento-landing-main .proyectos-contenedor .img-tarjetas .img-3 {
  background-image: url(../../img/landing/Rectangle\ 33.png);
  background-position: center;
  background-size: cover;
}
.mantenimiento-landing-main .proyectos-contenedor .img-tarjetas .img-4 {
  background-image: url(../../img/landing/AnaTome.png);
  background-position: center;
  background-size: cover;
}
.mantenimiento-landing-main .proyectos-contenedor .video {
  width: 37.75rem;
  height: 23rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: clamp(1rem, -7.74rem + 10.925vw, 5.37rem);
  margin-left: 3.94rem;
}
.mantenimiento-landing-main .proyectos-contenedor .video video {
  width: 150%;
  height: 150%;
  mix-blend-mode: lighten;
  rotate: 180deg;
}
.mantenimiento-landing-main .proyectos-responsive {
  display: none;
}
.mantenimiento-landing-main .landing-clientes {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  flex-shrink: 0;
  background: #fff;
  mix-blend-mode: difference;
  padding-bottom: clamp(4rem, 1.708rem + 3.056vw, 5.375rem);
}
.mantenimiento-landing-main .landing-clientes .texto {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  padding-left: 113px;
  padding-top: 40px;
  margin-bottom: 15px;
}
.mantenimiento-landing-main .landing-clientes .texto .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3.25rem + 5vw, 9.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: 126px; /* 85.135% */
}
.mantenimiento-landing-main .landing-clientes .texto .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: clamp(7rem, 3.25rem + 5vw, 9.25rem);
  font-style: normal;
  font-weight: 500;
  line-height: 126px;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta {
  width: 100%;
  display: flex;
  justify-content: center;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta {
  width: clamp(72rem, 7.417rem + 86.111vw, 110.75rem);
  flex-shrink: 0;
  border-top-left-radius: 29px;
  border-top-right-radius: 29px;
  background: #0e0e0e;
  display: flex;
  overflow: hidden;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-img-contenedor {
  width: 40%;
  height: auto;
  aspect-ratio: 586/685;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-img-contenedor .div-img {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background-image: url(../../img/landing/FotoFulltex.png);
  background-position: center;
  background-size: cover;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 60%;
  margin-left: 4rem;
  padding-top: clamp(9rem, 5.146rem + 5.139vw, 11.313rem);
  padding-bottom: 4rem;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .carita {
  position: absolute;
  top: 4.125rem;
  left: 0;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .manitos {
  margin-right: 62px;
  margin-bottom: 17.88px;
  gap: 1rem;
  display: flex;
  flex-direction: row;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .text-1 {
  color: #fff;
  font-family: NohemiRegular;
  font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
  font-style: normal;
  font-weight: 400;
  line-height: 41px; /* 157.692% */
  text-transform: uppercase;
  width: 70%;
  align-self: flex-start;
  height: clamp(9rem, 3.688rem + 7.083vw, 12.188rem);
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 62px;
  gap: 1rem;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha {
  background: none;
  border: none;
  cursor: pointer;
}
.mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda {
  rotate: 180deg;
  background: none;
  border: none;
  cursor: pointer;
}
.mantenimiento-landing-main .final {
  background-color: white;
  width: 100%;
  height: 100vh;
  padding-top: clamp(5rem, -1.875rem + 9.167vw, 9.125rem);
  position: relative;
}
.mantenimiento-landing-main .final .gmail {
  text-align: center;
}
.mantenimiento-landing-main .final .gmail a p {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: clamp(5rem, -0.104rem + 6.806vw, 8.063rem);
  font-style: normal;
  font-weight: 400;
  line-height: 114px; /* 88.372% */
}
.mantenimiento-landing-main .final .node-contenedor {
  margin-top: clamp(8rem, -1.271rem + 12.361vw, 13.563rem);
  display: flex;
  justify-content: space-between;
  padding-left: clamp(4rem, -1.521rem + 7.361vw, 7.313rem);
  padding-right: 12.9375rem;
}
.mantenimiento-landing-main .final .node-contenedor .div-img img {
  padding-left: 28px;
}
.mantenimiento-landing-main .final .node-contenedor .div-img .text {
  width: 561px;
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 21px;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha {
  width: 55rem;
  display: flex;
  flex-direction: column;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  padding-bottom: 35px;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-1 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-2 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-3 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-4 {
  color: #7c7c7c;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1.5fr;
  width: 100%;
  justify-content: center;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-5 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-5 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes {
  grid-column: 4;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-1 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-2 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-3 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-4 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-5 {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.056px; /* 162.173% */
}
.mantenimiento-landing-main .final .derechos-reservados {
  width: 100%;
  bottom: 1rem;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: end;
  padding-left: 85px;
  padding-right: 107px;
}
.mantenimiento-landing-main .final .derechos-reservados p {
  color: #0b0b0b;
  font-family: NohemiRegular;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* 310% */
}
.mantenimiento-landing-main .final .derechos-reservados .redes {
  height: -moz-max-content;
  height: max-content;
  display: flex;
  gap: 1rem;
  z-index: 20;
}
.mantenimiento-landing-main .final .derechos-reservados .redes div {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  border: 2px solid #0b0b0b;
}
.mantenimiento-landing-main .final .derechos-reservados .redes div img {
  width: 22px;
  height: 22px;
}
.mantenimiento-landing-main .final video {
  position: absolute;
  bottom: 0;
  right: 6.69rem;
  width: 12.5rem;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}

@media (max-width: 1000px) {
  .mantenimiento-landing-main .intro .soluciones-responsive {
    display: block;
    position: absolute;
    z-index: 20;
    top: 12.87rem;
    padding-left: 0.6rem;
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .texto-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(2.8rem, -4.04rem + 9.2vw, 7rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .texto-1 span {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(2.8rem, -4.04rem + 9.2vw, 7rem);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .texto-2 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: 0.9375;
    font-style: normal;
    font-weight: 400;
    line-height: 104%; /* 2.08rem */
    margin-top: clamp(-0.44rem, 3.32rem - 3.6vw, -1rem);
    margin-bottom: 1.25rem;
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .texto-3 {
    width: clamp(18.25rem, -39.25rem + 71.875vw, 47rem);
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(0.938rem, -1.188rem + 2.656vw, 2rem);
    font-style: normal;
    font-weight: 200;
    line-height: 104%; /* 2.08rem */
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .boton {
    display: flex;
    width: clamp(12rem, 9.455rem + 12.727vw, 19rem);
    height: clamp(2rem, 1.359rem + 3.204vw, 3.762rem);
    align-items: center;
    justify-content: center;
    gap: clamp(1rem, 0.734rem + 1.328vw, 1.731rem);
    flex-shrink: 0;
    border-radius: 6.02px;
    background: #eb5500;
    margin-top: 0.5rem;
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .boton p {
    padding-top: 6px;
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.709rem + 1.455vw, 1.8rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .mantenimiento-landing-main .intro .soluciones-responsive .boton img {
    width: clamp(0.649rem, 0.522rem + 0.638vw, 1rem);
    height: clamp(0.955rem, 0.829rem + 0.628vw, 1.3rem);
    flex-shrink: 0;
  }
  .mantenimiento-landing-main .intro .mascara-responsive {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7607843137);
    top: 0;
  }
  .mantenimiento-landing-main .soluciones-main {
    position: relative;
    height: 58vh;
  }
  .mantenimiento-landing-main .soluciones-main .soluciones-textos {
    display: none;
  }
  .mantenimiento-landing-main .soluciones-main .soluciones-responsive {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
  }
  .mantenimiento-landing-main .soluciones-main .soluciones-responsive .texto-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 3.56rem;
  }
  .mantenimiento-landing-main .soluciones-main .soluciones-responsive .texto-1 span {
    color: #fff;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mantenimiento-landing-main .soluciones-main .soluciones-responsive .texto-2 {
    margin-top: clamp(1rem, -1.292rem + 11.459vw, 5.87rem);
    margin-left: clamp(6.5rem, 4.382rem + 10.588vw, 11rem);
    text-align: start;
    width: 13.125rem;
    color: #fff;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 1.1rem */
  }
  .mantenimiento-landing-main .soluciones-main .soluciones-responsive img {
    width: 10rem;
    rotate: 60deg;
    position: absolute;
    left: 1rem;
    top: 20rem;
  }
  .mantenimiento-landing-main .soluciones-main .video-soluciones-contenedor {
    width: clamp(12rem, -44rem + 70vw, 40rem);
    height: 14.4375rem;
    top: 6.25;
    left: 0;
  }
  .mantenimiento-landing-main .importancia-mantenimiento {
    display: none;
  }
  .mantenimiento-landing-main .ventajas-contenedor {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    background-color: #0e0e0e;
    position: relative;
  }
  .mantenimiento-landing-main .ventajas-contenedor .mascara-blanca {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 13.375rem 0rem 0rem 0rem;
    position: absolute;
    background-color: rgb(255, 255, 255);
  }
  .mantenimiento-landing-main .ventajas-contenedor .arriba-texto {
    text-align: center;
    position: relative;
    z-index: 10;
    padding: 0%;
  }
  .mantenimiento-landing-main .ventajas-contenedor .arriba-texto .texto-1 {
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: 1.0625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 4.25rem;
  }
  .mantenimiento-landing-main .ventajas-contenedor .arriba-texto .texto-2 {
    width: clamp(19rem, 18.265rem + 3.676vw, 20.563rem);
    color: #0e0e0e;
    text-align: center;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115.573%; /* 1.15575rem */
    margin-left: clamp(1rem, 0.002rem + 4.988vw, 3.12rem);
  }
  .mantenimiento-landing-main .ventajas-contenedor .abajo-video {
    position: relative;
    margin-top: 1rem;
    z-index: 20 !important;
  }
  .mantenimiento-landing-main .ventajas-contenedor .abajo-video video {
    width: 100%;
    height: 17.6875rem;
    flex-shrink: 0;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main {
    height: -moz-max-content;
    height: max-content;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto {
    padding: 0;
    padding-left: clamp(0.5rem, 0.321rem + 0.894vw, 0.88rem);
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto .texto-1 {
    color: #0e0e0e;
    font-family: NohemiRegular;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115.573%; /* 1.80581rem */
    margin: 0;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto .texto-2 {
    width: clamp(18rem, 14.706rem + 16.471vw, 25rem);
    color: #0e0e0e;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115.573%; /* 1.15575rem */
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .arriba-texto .texto-2 span {
    color: #0e0e0e;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 115.573%;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: clamp(2rem, 0.409rem + 7.955vw, 6.375rem);
    position: relative;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor {
    width: clamp(19rem, 0.455rem + 92.727vw, 70rem);
    height: clamp(20rem, 14.864rem + 25.682vw, 34.125rem);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-image: url(../../img/landing/banerempresarial.png);
    background-position: center;
    background-size: cover;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos {
    padding-top: clamp(0.8rem, -0.409rem + 6.045vw, 4.125rem);
    padding-left: clamp(0.3rem, 0.114rem + 0.932vw, 0.813rem);
    position: relative;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-1 {
    font-size: clamp(2.4rem, 1.909rem + 1.455vw, 3rem);
    line-height: clamp(1.9rem, 0.932rem + 4.841vw, 4.563rem);
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-2 {
    display: none;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-3 {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(2.4rem, 1.964rem + 2.182vw, 3.6rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(3rem, 2.5rem + 2.5vw, 4.375rem); /* 70.213% */
    margin-bottom: clamp(0.4rem, 0.318rem + 0.409vw, 0.625rem);
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .mantenimiento-contenedor .mantenimiento-textos .text-4 {
    color: #fdf9f9;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.704rem + 1.478vw, 1.813rem);
    font-style: normal;
    font-weight: 400;
    line-height: clamp(1.4rem, 1.159rem + 1.205vw, 2.063rem); /* 113.793% */
    width: clamp(18rem, 11.25rem + 33.75vw, 36.563rem);
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .boton {
    display: flex;
    width: clamp(15rem, 13.826rem + 5.872vw, 17.496rem);
    height: 2.375rem;
    padding: 0.64331rem 0.66719rem;
    justify-content: center;
    align-items: center;
    gap: 1.09606rem;
    flex-shrink: 0;
    border-radius: 0.23825rem;
    bottom: 2rem;
  }
  .mantenimiento-landing-main .servicios-mantenimiento-main .landing-mantenimiento .boton a p {
    color: #eb5500;
    font-family: NohemiRegular;
    font-size: clamp(1rem, 0.764rem + 1.179vw, 1.501rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .mantenimiento-landing-main .fulltex-mantenimiento-main {
    height: -moz-max-content;
    height: max-content;
    padding: 0%;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 4.25rem;
  }
  .mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto .texto-1 {
    margin: 0%;
    width: clamp(19rem, 16.176rem + 14.118vw, 25rem);
    color: #0e0e0e;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 115.573%; /* 1.15575rem */
    margin-bottom: 3.31rem;
  }
  .mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto .texto-2 {
    color: #0e0e0e;
    font-family: NohemiRegular;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 115.573%; /* 1.15575rem */
    margin: 0%;
    padding-right: 0.87rem;
  }
  .mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto .texto-2 span {
    color: #0e0e0e;
    font-family: NohemiRegular;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 300;
    line-height: 115.573%; /* 1.58913rem */
  }
  .mantenimiento-landing-main .fulltex-mantenimiento-main .izquierda-texto img {
    width: 10.75rem;
    bottom: 2.56rem;
    left: 30%;
    position: absolute;
  }
  .mantenimiento-landing-main .fulltex-mantenimiento-main .derecha-imagen {
    width: clamp(21rem, 19.088rem + 9.559vw, 25.063rem);
    height: 19.25rem;
    flex-shrink: 0;
    margin-bottom: 8.56rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba {
    flex-direction: column;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding: 1.19rem 1rem;
    border-right: none;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 {
    font-size: 2.109rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-1 span {
    font-size: 3.13344rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .izquierda .texto-2 {
    width: 19.75rem;
    font-size: 1rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    padding: 1.19rem 1rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-1 {
    font-size: 1rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .arriba .derecha .texto-2 {
    width: 100%;
    text-align: center;
    font-size: 1.375rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba {
    width: 100%;
    flex-direction: column;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda {
    width: 100%;
    border-right: none;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre {
    width: 100%;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-1 {
    font-size: 1rem;
    opacity: 0.38;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 {
    font-size: 0.625rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre .texto-2 span {
    color: #eb5500;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .izquierda .arriba-nombre input {
    font-size: 1rem !important;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-arriba .derecha .texto-1 {
    width: 17.5625rem;
    font-size: 1rem;
    opacity: 0.38;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo {
    flex-direction: column;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda {
    width: 100%;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre {
    width: 100%;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-1 {
    color: #7c7c7c;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .izquierda .arriba-nombre .texto-2 span {
    color: #eb5500;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha {
    width: 100%;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .formulario-borde .medio-abajo .derecha .texto-1 {
    width: 17.5625rem;
    font-size: 1rem;
    opacity: 0.38;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints {
    width: 100%;
    margin-top: 1.25rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .izquierda {
    opacity: 0;
    flex-direction: column;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .arriba {
    display: none;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton {
    padding-right: 0.5rem;
    padding-top: 0.44rem;
    padding-left: 8.5rem;
  }
  .mantenimiento-landing-main .mantenimiento-formulario .botones-checkpoints .derecha .abajo .contenedor-boton button {
    font-size: 1.16463rem;
  }
  .mantenimiento-landing-main .pregunas-frecuentes {
    flex-direction: column;
  }
  .mantenimiento-landing-main .pregunas-frecuentes .text-img {
    width: 100%;
  }
  .mantenimiento-landing-main .pregunas-frecuentes .text-img .text-1 {
    font-size: clamp(2rem, 12vw, 6rem);
    line-height: clamp(1.5rem, 10vw, 5rem); /* 88.372% */
    padding-left: 1rem;
  }
  .mantenimiento-landing-main .pregunas-frecuentes .text-img .text-2 {
    font-size: clamp(2rem, 12vw, 6rem);
    line-height: clamp(1.5rem, 10vw, 5rem); /* 88.372% */
    padding-left: 1rem;
  }
  .mantenimiento-landing-main .pregunas-frecuentes .text-img .video-contenedor {
    width: 100%;
    height: auto;
    aspect-ratio: 788/484;
  }
  .mantenimiento-landing-main .pregunas-frecuentes .text-img .video-contenedor video {
    left: -20%;
    bottom: clamp(-2rem, -5vw, -5rem);
    width: 150%;
    height: auto !important;
  }
  .mantenimiento-landing-main .pregunas-frecuentes .preguntas-contenedor {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .mantenimiento-landing-main .proyectos-contenedor {
    display: none;
  }
  .mantenimiento-landing-main .proyectos-responsive {
    width: 100%;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 3rem;
  }
  .mantenimiento-landing-main .proyectos-responsive .texto-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(1.5rem, 0.824rem + 3.382vw, 2.938rem);
    font-style: normal;
    font-weight: 400;
    line-height: 2.9375rem; /* 100% */
  }
  .mantenimiento-landing-main .proyectos-responsive .texto-1 .numero {
    color: #fff;
    font-family: NohemiRegular;
    font-size: 1.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.9375rem; /* 151.613% */
  }
  .mantenimiento-landing-main .proyectos-responsive .texto-1 .letras {
    color: #fff;
    font-family: NohemiRegular;
    font-size: clamp(2.8rem, 2.559rem + 1.206vw, 3.313rem);
    font-style: normal;
    font-weight: 500;
    line-height: 2.9375rem;
  }
  .mantenimiento-landing-main .proyectos-responsive img {
    width: 10rem;
    position: absolute;
    rotate: 30deg;
    left: 1.1rem;
    top: 10rem;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 4.75rem;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .tarjeta {
    width: clamp(19rem, 16.382rem + 13.089vw, 24.563rem);
    height: 8.9375rem;
    text-align: end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: clamp(5rem, 1.974rem + 15.129vw, 11.43rem);
    padding-right: 0.5rem;
    border-radius: 0.14463rem;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .tarjeta .texto-1 {
    color: #fff;
    font-family: NohemiRegular;
    font-size: 0.88275rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .tarjeta .texto-2 {
    color: #fff;
    text-align: right;
    font-family: NohemiRegular;
    font-size: 0.7rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .FT {
    background-image: url(../../img/casaFulltex.webp);
    background-position: center;
    background-size: cover;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .BM {
    background-image: url(../../img/landing/Baires.png);
    background-position: center;
    background-size: cover;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .BVA {
    background-image: url(../../img/landing/Rectangle\ 33.png);
    background-position: center;
    background-size: cover;
  }
  .mantenimiento-landing-main .proyectos-responsive .tarjetas-contenedor .ANA {
    background-image: url(../../img/landing/AnaTome.png);
    background-position: center;
    background-size: cover;
  }
  .mantenimiento-landing-main .proyectos-responsive .video-contenedor {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mantenimiento-landing-main .proyectos-responsive .video-contenedor video {
    width: 100%;
    height: 12.5rem;
    flex-shrink: 0;
    mix-blend-mode: lighten;
  }
  .mantenimiento-landing-main .landing-clientes .texto {
    padding-left: clamp(1rem, 10vw, 10rem);
  }
  .mantenimiento-landing-main .landing-clientes .texto .text-1 {
    font-size: clamp(4rem, 7vw, 7rem);
    line-height: unset; /* 85.135% */
  }
  .mantenimiento-landing-main .landing-clientes .texto .text-2 {
    font-size: clamp(4rem, 7vw, 7rem);
    line-height: unset; /* 85.135% */
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 0;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .manitos img {
    width: clamp(2rem, 4vw, 4rem);
    height: auto;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .text-1 {
    height: 12rem !important;
    font-size: clamp(1rem, -0.042rem + 1.389vw, 1.625rem);
    height: -moz-fit-content;
    height: fit-content;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas {
    position: absolute;
    bottom: 5%;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha img {
    aspect-ratio: 1/1;
    width: clamp(3rem, 5vw, 5.9rem);
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda img {
    aspect-ratio: 1/1;
    width: clamp(3rem, 5vw, 5.9rem);
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta {
    flex-direction: column;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-img-contenedor {
    width: 100%;
    aspect-ratio: 429/300;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text {
    width: 100%;
    display: grid;
    grid-template-rows: 2fr 1fr 1fr;
    grid-template-columns: 1fr 5fr 1fr;
    margin: 0;
    padding: 0 1rem;
    padding-top: 2rem;
    justify-content: center;
    text-align: center;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .carita {
    position: relative;
    grid-row: 2;
    align-self: flex-start;
    margin: auto 0;
    top: unset;
    left: unset;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .manitos {
    grid-row: 2;
    grid-column: 3;
    align-self: flex-end;
    margin: auto;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .text-1 {
    grid-row: 1;
    grid-column: span 3;
    width: 100%;
    line-height: clamp(1.2rem, 0.918rem + 1.412vw, 1.8rem);
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas {
    grid-row: 3;
    flex-direction: row;
    align-self: center;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    align-items: center;
    position: relative;
    grid-column: span 3;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha {
    grid-column: 3;
    grid-row: 1;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-derecha img {
    width: clamp(3rem, 10vw, 5.9rem);
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda {
    grid-column: 1;
    grid-row: 1;
  }
  .mantenimiento-landing-main .landing-clientes .contenedor-de-la-tarjeta .tarjeta .div-text .flechas .flecha-izquierda img {
    width: clamp(3rem, 10vw, 5.9rem);
  }
  .mantenimiento-landing-main .final {
    margin-top: 0;
    height: unset;
  }
  .mantenimiento-landing-main .final .gmail {
    text-align: left;
  }
  .mantenimiento-landing-main .final .gmail p {
    padding-left: clamp(1rem, -1.521rem + 7.361vw, 7.313rem);
    padding-right: clamp(1.042rem, -1.521rem + 7.361vw, 7.313rem);
    font-size: clamp(1rem, 6.5vw, 5rem);
    line-height: unset;
  }
  .mantenimiento-landing-main .final .node-contenedor {
    margin-top: 1rem;
    padding-left: clamp(1rem, -1.521rem + 7.361vw, 7.313rem);
    padding-right: clamp(1.042rem, -1.521rem + 7.361vw, 7.313rem);
  }
  .mantenimiento-landing-main .final .node-contenedor .div-img img {
    width: clamp(5rem, 25vw, 13.5rem);
    height: auto;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-img .text {
    width: clamp(12rem, 60vw, 35rem);
    color: #0b0b0b;
    font-family: NohemiRegular;
    font-size: clamp(0.5rem, 2vw, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 21px;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha {
    width: 100%;
    padding-top: 2rem;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios {
    align-items: center;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .servicios .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades {
    padding-left: 0;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda {
    margin-right: 0;
    grid-column: 2;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .izquierda .text-5 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha {
    grid-column: 3;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha .text-5 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes {
    grid-column: 4;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-1 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-2 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-3 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-4 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .planes .text-5 {
    font-size: clamp(0.6rem, 2vw, 1.3rem);
    line-height: unset; /* 162.173% */
  }
  .mantenimiento-landing-main .final .derechos-reservados {
    padding: 4rem clamp(1rem, -1.521rem + 7.361vw, 7.313rem);
  }
  .mantenimiento-landing-main .final .derechos-reservados p {
    font-size: clamp(0.55rem, 2vw, 1.25rem);
  }
  .mantenimiento-landing-main .final .derechos-reservados .redes div img {
    width: clamp(0.6rem, 2.2vw, 1.4rem);
    height: clamp(0.6rem, 2.2vw, 1.4rem);
  }
  .mantenimiento-landing-main .final {
    height: unset;
    position: relative;
  }
  .mantenimiento-landing-main .final .gmail p {
    font-size: clamp(1.3rem, 0.56rem + 3.7vw, 5rem) !important;
  }
  .mantenimiento-landing-main .final .node-contenedor {
    margin-top: 4rem;
    padding-left: clamp(4rem, -1.521rem + 7.361vw, 7.313rem);
    padding-right: clamp(4rem, -1.521rem + 7.361vw, 7.313rem);
    flex-direction: column;
    z-index: 20 !important;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-img img {
    padding-left: 0;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha {
    width: 100%;
    padding-top: 2rem;
    z-index: 20 !important;
  }
  .mantenimiento-landing-main .final .node-contenedor .div-derecha .cualidades .derecha {
    gap: 1rem;
  }
  .mantenimiento-landing-main .final .derechos-reservados {
    bottom: unset;
    position: relative;
    padding: 4rem;
    z-index: 10;
  }
  .mantenimiento-landing-main .final video {
    left: 0;
  }
}/*# sourceMappingURL=mantenimiento.css.map */