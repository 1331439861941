.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.buttonContainer .button {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  z-index: 10;
}
.buttonContainer .circle {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  transform: scale(0);
  transform-origin: center center;
  border-radius: 50%;
}/*# sourceMappingURL=index.css.map */