.fulltex-main {
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: NohemiRegular;
  gap: 4rem;
  cursor: default;
  overflow: hidden;
}
.fulltex-main .fulltex-contenedor {
  background-color: white;
  width: 100%;
  display: grid;
  padding: 0 4rem;
  padding-top: 8.4rem;
  grid-template-columns: 1fr 1fr;
}
.fulltex-main .fulltex-contenedor .fulltex-titulo {
  position: relative;
  padding-bottom: 15rem;
  padding-right: 5rem;
}
.fulltex-main .fulltex-contenedor .fulltex-logo {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.fulltex-main .fulltex-contenedor .fulltex-doodle {
  width: clamp(10rem, 25vw, 25rem);
  height: auto;
}
.fulltex-main .fulltex-contenedor .fulltex-titulo h5 {
  font-size: clamp(3rem, 9vw, 9rem);
  margin: 0%;
  padding: 0%;
  line-height: 5.9rem;
  align-self: start;
}
.fulltex-main .fulltex-contenedor .fulltex-titulo span {
  padding-left: 3.5rem;
}
.fulltex-main .fulltex-contenedor .fulltex-titulo .fulltex-rayita {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 20%;
}
.fulltex-main .fulltex-contenedor .fulltex-rayita-mobile {
  display: none;
}
.fulltex-main .fulltex-contenedor .fulltex-text {
  font-size: 1.1rem;
  margin: 0 auto;
  max-width: 25rem;
  font-family: NohemiRegular;
  cursor: default;
}

.fulltex-main .contenedor-contexto {
  width: 100%;
  height: clamp(35rem, 15rem + 35vw, 50rem);
  background-color: white;
  background-image: url("../../img/fondoTexturaLateral.png");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
/*.contenedor-contexto::before {
  background-image: url("../../img/FondoTextura.png");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200rem;

 
}*/
.fulltex-main .contenedor-contexto .titulos-contexto-contenedor {
  width: fit-content;
  padding-left: 3rem;
  padding-top: 10rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.fulltex-main .contenedor-contexto .titulos-contexto-contenedor h1 {
  font-size: clamp(3rem, 9vw, 8rem);
  padding: 0%;
  color: rgb(0, 0, 0);
  margin: 0%;
  align-self: start;
}
.fulltex-main .contenedor-contexto .fulltex-doodle {
  width: clamp(10rem, 25vw, 25rem);
  height: auto;
}
.fulltex-main .contenedor-balde {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  padding: 0 4rem;
  gap: 0 2rem;
}
.fulltex-main .contenedor-balde .balde {
  width: clamp(20rem, 10rem + 30vw, 38.6875rem);
  height: auto;
}
.fulltex-main .contenedor-balde p {
  font-size: clamp(1rem, 2vw, 1.7rem);
  max-width: 40rem;
}
.fulltex-main .rayitas-naranjas {
  width: clamp(5rem, 9vw, 9.125rem);
  height: auto;
}
.fulltex-main .fulltex-contenedor-doodles {
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-left: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.fulltex-main .desafio-contenedor {
  display: flex;
  justify-content: flex-end;
  padding: 0 4rem;
}
.fulltex-main .desafio-contenedor .desafio-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.fulltex-main .desafio-text h1 {
  font-size: 8rem;
  padding: 0%;
  color: rgb(0, 0, 0);

  margin: 0%;
}

.fulltex-main .desafio-contenedor .fulltex-doodle {
  width: clamp(20rem, 25vw, 25rem);
  height: auto;
}
.fulltex-main .contenedor-balde-textos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 4rem;
}
.fulltex-main .contenedor-balde-textos .contenedor-text-p {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  grid-row: 1 / span 2;
}
.fulltex-main .contenedor-balde-textos .contenedor-text-p p {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.fulltex-main .contenedor-balde-textos .contenedor-text-p img {
  opacity: 0.5;
  width: clamp(30rem, 45vw, 61.375rem);
  align-self: center;
}
.fulltex-main .contenedor-balde-textos .contenedor-balde-membrana {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 2;
  width: 100%;
  height: 100%;
}
.fulltex-main
  .contenedor-balde-textos
  .contenedor-balde-membrana
  .muestra-membrana {
  width: clamp(25rem, 30vw, 34.375rem);
  height: clamp(20rem, 5rem + 20vw, 26.5625rem);
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  grid-row: 1;
  grid-column: 2;
}
.fulltex-main .contenedor-balde-textos .contenedor-balde-membrana .balde {
  width: clamp(30rem, 45vw, 61.375rem);
  height: auto;
}
.fulltex-main .conclusion-titulo {
  display: flex;
  width: 100%;
  padding: 0 4rem;
  justify-content: flex-start;
}
.fulltex-main .conclusion-titulo .conclusion-h1-img {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.fulltex-main .conclusion-titulo .conclusion-h1-img h1 {
  font-size: clamp(3rem, 9vw, 8rem);
  padding: 0%;
  color: rgb(0, 0, 0);
  margin: 0%;
}
.fulltex-main .conclusion-titulo .conclusion-h1-img .fulltex-doodle {
  width: clamp(20rem, 25vw, 25rem);
  height: auto;
}
.fulltex-main .conclusion-contenido {
  display: grid;
  padding: 0 4rem;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}
.fulltex-main .conclusion-contenido p {
  padding: 0 2rem;
  font-size: 1.5rem;
}
.fulltex-main .conclusion-contenido .casa-fulltex {
  height: 26.5625rem;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.fulltex-main .fulltex-link {
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  padding: 0 4rem;
  padding-bottom: 4rem;
}
.fulltex-main .fulltex-link h1 {
  font-size: clamp(3rem, 7vw, 8rem);
  margin: 0%;
  line-height: 90%;
  padding-top: 15px;
}
.fulltex-main .fulltex-link .button {
  border: 10px solid rgb(0, 0, 0);
}
.fulltex-main .fulltex-link a {
  color: rgb(0, 0, 0);
  padding: 1rem 3rem;
  transition: background-color 0.3s, color 0.3s;
}
.fulltex-main .fulltex-link:hover a:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

@media (max-width: 1100px) {
  .fulltex-main .contenedor-balde-textos {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }
  .fulltex-main .contenedor-balde-textos .first-on-mobile {
    grid-row: 1 !important;
  }
  .fulltex-main .contenedor-balde-textos .contenedor-balde-membrana {
    grid-column: 1;
    grid-row: 3;
  }
  .fulltex-main
    .contenedor-balde-textos
    .contenedor-balde-membrana
    .muestra-membrana {
    width: 100%;
    height: 80%;
  }
  .fulltex-main .contenedor-balde-textos .contenedor-text-p {
    grid-row: 2;
  }
}

@media (max-width: 1100px) {
  .fulltex-main {
    display: flex;
    flex-direction: column;
  }
  .fulltex-main .fulltex-contenedor {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
  }
  .fulltex-main .fulltex-contenedor .fulltex-titulo {
    padding: 0;
  }
  .fulltex-main .fulltex-contenedor .fulltex-titulo .fulltex-rayita {
    display: none;
  }
  .fulltex-main .fulltex-contenedor .fulltex-rayita-mobile {
    display: flex;
    align-self: center;
  }
  .fulltex-main .desafio-contenedor {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fulltex-main .contenedor-balde {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 0 4rem 0 0;
  }
  .fulltex-main .contenedor-balde .textContainer {
    grid-row: 2;
    grid-column: 1 / -1;
    justify-self: flex-end;
    max-width: clamp(20rem, 60vw, 60rem);
  }
  .fulltex-main .contenedor-balde .rayitasContainer {
    align-self: flex-end;
    justify-self: flex-end;
  }
  .fulltex-main .contenedor-balde .balde {
    align-self: flex-end;
    justify-self: flex-start;
  }
  .fulltex-main .fulltex-contenedor-doodles {
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }
  .fulltex-main .fulltex-contenedor-doodles .fulltex-logo img {
    width: clamp(30rem, 20rem + 45vw, 51.125rem);
    height: auto;
  }
  .fulltex-main .fulltex-contenedor-doodles .fulltex-simbolos-completos img {
    width: clamp(25rem, 10rem + 52vw, 47.25rem);
    height: auto;
  }
  .fulltex-main .conclusion-contenido {
    display: flex;
    flex-direction: column;
  }
  .fulltex-main .conclusion-contenido .casa-fulltex {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .fulltex-main .contenedor-contexto {
    height: 26.875rem;
  }
  .fulltex-main .contenedor-contexto .titulos-contexto-contenedor {
    padding: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  .fulltex-main .contenedor-contexto .titulos-contexto-contenedor h1 {
    font-size: clamp(3rem, 16vw, 8rem);
    line-height: 50%;
    align-self: flex-end;
    padding-right: 2rem;
  }
  .fulltex-main .contenedor-contexto .titulos-contexto-contenedor img {
    width: clamp(15rem, 47vw, 23rem);
    height: auto;
    padding-right: 2rem;
    align-self: flex-end;
  }
  .fulltex-main .desafio-contenedor .desafio-text h1 {
    font-size: clamp(6rem, 2rem + 12vw, 8rem);
  }
  .fulltex-main .desafio-contenedor .fulltex-doodle {
    width: clamp(15rem, 5rem + 30vw, 20rem);
  }
  .fulltex-main .contenedor-balde-textos {
    padding: 4rem 1rem;
    grid-template-rows: 0.6fr 2fr 0.6fr;
  }
  .fulltex-main
    .contenedor-balde-textos
    .contenedor-balde-membrana
    .muestra-membrana {
    width: 100%;
  }
  .fulltex-main .contenedor-balde-textos .contenedor-balde-membrana .balde {
    width: clamp(25rem, 10rem + 60vw, 40rem);
  }
  .fulltex-main .contenedor-balde-textos .contenedor-text-p img {
    width: clamp(25rem, 80vw, 40rem);
  }
  .fulltex-main .fulltex-link h1 {
    font-size: clamp(1.5rem, 6vw, 3.5rem);
    margin: 0%;
    line-height: 90%;
    padding-top: 15px;
  }
}

@media (max-width: 600px) {
  .fulltex-main .fulltex-contenedor-doodles .fulltex-logo img {
    width: 100%;
    height: auto;
  }
  .fulltex-main .fulltex-contenedor-doodles .fulltex-simbolos-completos {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .fulltex-main .fulltex-contenedor-doodles .fulltex-simbolos-completos img {
    width: 90%;
    height: auto;
    align-self: center;
    text-align: center;
  }
  .fulltex-main .desafio-contenedor .desafio-text h1 {
    font-size: clamp(4rem,  12vw, 6rem);
  }
  .fulltex-main .contenedor-balde-textos .contenedor-text-p img {
    width: 90%;
  }
  .fulltex-main .contenedor-balde-textos .contenedor-balde-membrana .balde {
    width: 100%;
  }
  .fulltex-main .conclusion-titulo {
    padding: 0;
    justify-content: center;
  }
  .fulltex-main .fulltex-link h1 {
    font-size: clamp(1rem, 5vw, 3rem);
  }
}