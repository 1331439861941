.contenedor-planes {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0b0b0b;
  gap: 2rem;
  .header {
    width: 100%;
    height: 800px;
    margin: 0%;
    padding: 0 4rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .planes-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .planes-background-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(11, 11, 11, 0) 10.41%,
        rgba(11, 11, 11, 0) 10.41%,
        #0b0b0b 100.02%,
        #0b0b0b 100.86%
      );
      z-index: 1;
    }
    h1 {
      font-family: HumaneRegular;
      font-weight: 100;
      font-size: 11rem;
      padding: 0%;
      color: white;
      position: relative;
      z-index: 2;
      cursor: default;
    }
    p {
      font-family: NohemiRegular;
      font-weight: 100;
      font-size: 1.3rem;
      line-height: 120%;
      height: max-content;
      margin: 0%;
      padding: 0;
      color: white;
      z-index: 2;
      position: relative;
      cursor: default;
    }
  }
  .contenedor-tarjeta {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    padding: 0 4rem;
    gap: 4rem 0;
    align-items: center;
    padding-top: 4rem;
    .contenedor-tarjeta-img {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      justify-content: center;
      overflow: hidden;
      border-radius: 10px;
      min-height: 25rem;
      height: 100%;
      .tarjeta1 {
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-out;
        position: absolute;
        opacity: 0;
        object-fit: cover;
      }
      .contenedor-tarjeta-text {
        border: 2px solid rgb(255, 255, 255);
        position: relative;
        display: grid;
        grid-template-columns: 1.5fr 1.5fr 1fr;
        margin: 2rem 1rem;
        width: 98%;
        min-height: 90%;
        .h1-p-contenedor {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 3rem 0;
          margin-left: 1rem;
          color: white;
        }

        .ul-contenedor {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          list-style-position: inside;
          p {
            color: white;
            cursor: default;
          }
          li {
            font-family: "Encode Sans Condensed", sans-serif;
            color: white;
            font-size: 0.9rem;
            transition: all 0.5s linear;
            padding-left: 2rem;
            padding-right: 1rem;
            line-height: 1.1rem;
            cursor: default;
          }
        }

        .p-a-contenedor {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2rem 0;
          color: white;
          .planes--button {
            .proyectosButton {
              border: 1px solid white;
              .testButton {
                width: 210px;
                padding: 1rem 1.5rem;
                a {
                  font-size: 1.25rem;
                  font-family: NohemiRegular;
                  transition: all 0.5s linear;
                  color: white;
                }
              }
            }
            .proyectosButton:hover {
              a {
                color: black;
              }
            }
          }
        }

        .arriba-izquierda {
          width: 1.25rem;
          height: 1.25rem;
          position: absolute;
          top: 7px;
          left: 7px;
        }

        .arriba-derecha {
          width: 1.25rem;
          height: 1.25rem;
          position: absolute;
          top: 7px;
          right: 0.4375rem;
          transform: rotate(180deg);
        }

        .abajo-izquierda {
          width: 1.25rem;
          height: 1.25rem;
          position: absolute;
          bottom: 7px;
          left: 7px;
        }

        .abajo-derecha {
          width: 1.25rem;
          height: 1.25rem;
          position: absolute;
          bottom: 7px;
          right: 7px;
          transform: rotate(180deg);
        }

        h1 {
          font-family: HumaneRegular;
          font-weight: 100;
          font-size: 8rem;
          margin-top: -2rem;
          margin-bottom: -2rem;
          padding: 0%;
          transition: all 0.5s linear;
          color: white;
          height: min-content;

          cursor: default;
        }
      }
    }
    .contenedor-tarjeta-img:hover {
      .tarjeta1 {
        opacity: 1;
      }
      .hover-text {
        color: white;
        li {
          color: white;
        }
      }
      .p-a-contenedor {
        p {
          color: white;
          cursor: default;
        }
      }
    }
  }
  .contenedor-tarjeta-final {
    p {
      font-family: "OpenSans-CondLight" Op;
      font-weight: 100;
      font-size: 1.5rem;
      color: rgb(78, 78, 78);
      transition: all 0.5s linear;
      text-align: center;
      text-transform: uppercase;
      cursor: default;
    }
  }
}

@media screen and (max-width: 800px) {
  .contenedor-planes {
    .header {
      width: auto; /* Cambiado a auto para ajustarse al ancho del contenido en pantallas más pequeñas */
      padding-left: 2em; /* Reducido el padding para evitar desbordamientos */
      .planes-background-gradient {
        display: block;
      }
      h1 {
        font-size: 7rem; /* Tamaño de fuente más pequeño para pantallas más pequeñas */
        cursor: default;
      }
      p {
        font-size: 1rem; /* Tamaño de fuente más pequeño para pantallas más pequeñas */
        cursor: default;
      }
    }
    .contenedor-tarjeta {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      .contenedor-tarjeta-img {
        height: auto;
        .tarjeta1 {
          opacity: 1;
        }
        .contenedor-tarjeta-text {
          display: flex;
          flex-direction: column;
          height: auto;
          align-items: center;
          justify-content: center;
          gap: 2rem;
          .h1-p-contenedor {
            padding: 0 1rem;
            padding-top: 2rem;
            margin: 0;
          }
          .ul-contenedor {
            padding: 0 1rem;
            align-items: center;
            text-align: center;
          }
          .p-a-contenedor {
            padding: 0 1rem;
            padding-bottom: 2rem;
            align-items: center;
            text-align: center;
          }
        }
      }
    }
  }
}
