.sobre-nosotros-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  background-color: #0b0b0b;
}
.sobre-nosotros-container .seccionBlanca {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-image: url("../../img/backgroundSobreNosotrosMobile.webp");
  background-size: cover;
  background-position: center;
  background-color: white;
  align-items: center;
}
.sobre-nosotros-container .seccionBlanca .sobre-nosotros {
  padding-top: 2rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 3rem;
}
.sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(11, 11, 11, 0) 10.41%, rgba(11, 11, 11, 0) 10.41%, #0b0b0b 100.02%, #0b0b0b 100.86%);
  z-index: 1;
  display: none;
}
.sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-titulo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
  grid-column: span 2;
  z-index: 2;
  height: 50rem;
  padding-top: 10rem;
}
.sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-titulo h1 {
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: clamp(5rem, 2rem + 6vw, 8rem);
  color: white;
  margin: 0%;
  cursor: default;
}
.sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-titulo p {
  color: white;
  font-family: NohemiRegular;
  font-size: clamp(1rem, 1vw, 1.5rem);
  font-style: normal;
  font-weight: 100;
  line-height: 120%;
  max-width: 37.5rem;
  cursor: default;
}
.sobre-nosotros-container .seccionBlanca .pie-de-pagina-b {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 4rem;
}
.sobre-nosotros-container .seccionBlanca .pie-de-pagina-b p {
  z-index: 100;
  font-size: 1rem;
  color: white;
  font-family: "Open Sans", sans-serif;
  cursor: default;
}
.sobre-nosotros-container .seccionBlanca .pie-de-pagina-b a {
  z-index: 100;
}
.sobre-nosotros-container .seccionBlanca .nuestra-vision {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  gap: 6rem;
  padding: 0 3rem;
  justify-content: center;
}
.sobre-nosotros-container .seccionBlanca .nuestra-vision .nuestra-text {
  border-radius: 10px;
  background: rgba(212, 212, 212, 0.08);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(4.4000000954px);
          backdrop-filter: blur(4.4000000954px);
  display: flex;
  flex-direction: column;
  padding: 4rem 1.5rem;
}
.sobre-nosotros-container .seccionBlanca .nuestra-vision .nuestra-text h1 {
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: clamp(4rem, 8vw, 8rem);
  cursor: default;
  color: white;
  padding: 0 1rem;
}
.sobre-nosotros-container .seccionBlanca .nuestra-vision .nuestra-text p {
  font-family: NohemiRegular;
  font-weight: 100;
  font-size: clamp(1rem, 1.5vw, 1.5625rem);
  padding: 0 1rem;
  color: white;
  cursor: default;
}
.sobre-nosotros-container .seccionBlanca .comprendemos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 2rem;
}
.sobre-nosotros-container .seccionBlanca .comprendemos .comprendemos-text {
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  height: -moz-max-content;
  height: max-content;
}
.sobre-nosotros-container .seccionBlanca .comprendemos .comprendemos-text p {
  color: white;
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: 100;
  margin: 0%;
  font-size: 1.5rem;
  cursor: default;
}
.sobre-nosotros-container .seccionNegra {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #0b0b0b;
}
.sobre-nosotros-container .seccionNegra .equipo-contenedor {
  width: 100%;
  height: 30em;
  padding-top: 4rem;
}
.sobre-nosotros-container .seccionNegra .equipo-contenedor .equipo-text {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  text-align: center;
}
.sobre-nosotros-container .seccionNegra .equipo-contenedor .equipo-text h1 {
  margin: 0%;
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: 14rem;
  color: rgb(255, 255, 255);
  padding: 0 1rem;
  cursor: default;
}
.sobre-nosotros-container .seccionNegra .equipo-contenedor .equipo-text h2 {
  font-family: NohemiRegular;
  font-weight: 100;
  margin: 0%;
  font-size: 1.5rem;
  color: #484848;
  cursor: default;
}
.sobre-nosotros-container .seccionNegra .contacto-final {
  width: 100%;
  height: 20em;
  display: flex;
  justify-content: center;
  align-items: end;
}
.sobre-nosotros-container .seccionNegra .contacto-final h1 {
  font-size: 11rem;
  color: white;
  font-family: HumaneRegular;
  font-weight: 100;
  margin: 0%;
  padding: 0%;
}
.sobre-nosotros-container .seccionNegra hr {
  padding: 0%;
  margin: 0%;
}

@media (max-width: 800px) {
  .sobre-nosotros-container {
    padding-top: 0;
  }
  .sobre-nosotros-container .seccionBlanca {
    background-color: #0b0b0b;
    background-image: none;
  }
  .sobre-nosotros-container .seccionBlanca .sobre-nosotros {
    background-color: #0b0b0b;
    background-image: url("../../img/backgroundSobreNosotrosMobile.webp");
    background-size: 150%;
    background-position: center;
    gap: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 600px;
  }
  .sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-background-gradient {
    display: block;
  }
  .sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-titulo {
    padding: 6rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-titulo h1 {
    color: white;
  }
  .sobre-nosotros-container .seccionBlanca .sobre-nosotros .nosotros-titulo p {
    color: white;
    align-self: flex-end;
  }
  .sobre-nosotros-container .seccionBlanca .nuestra-vision {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #0b0b0b;
  }
  .sobre-nosotros-container .seccionBlanca .nuestra-vision .nuestra-text {
    display: flex;
    flex-direction: column;
    padding: 4rem 1.5rem;
    background-color: rgba(212, 212, 212, 0.08);
  }
  .sobre-nosotros-container .seccionBlanca .nuestra-vision .nuestra-text h1 {
    color: white;
    padding: 0;
  }
  .sobre-nosotros-container .seccionBlanca .nuestra-vision .nuestra-text p {
    color: white;
    padding: 0;
  }
  .sobre-nosotros-container .seccionBlanca .comprendemos {
    background-color: #0b0b0b;
  }
  .sobre-nosotros-container .seccionBlanca .comprendemos .comprendemos-text {
    padding: 0 2rem;
  }
  .sobre-nosotros-container .seccionBlanca .comprendemos .comprendemos-text p {
    color: white;
  }
  .sobre-nosotros-container .seccionNegra .equipo-contenedor .equipo-text h1 {
    font-size: clamp(7rem, 3rem + 20vw, 14rem);
  }
  .sobre-nosotros-container .seccionNegra .equipo-contenedor .equipo-text h2 {
    font-size: 1rem;
  }
  .sobre-nosotros-container .seccionNegra .contacto-final {
    height: auto;
    padding: 4rem 0;
  }
  .sobre-nosotros-container .seccionNegra .contacto-final h1 {
    font-size: clamp(7rem, 3rem + 20vw, 14rem);
  }
}/*# sourceMappingURL=sobrenosotros.css.map */