@font-face {
  font-family: "Encode Sans Condensed";
  src: url(https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap);
}
.footer {
  display: none !important;
  height: 9em;
  padding-left: 3em;
  padding-right: 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background-color: #0b0b0b;
}
.footer .footerImagen {
  display: none;
}
.footer .footerGradient {
  display: none;
}
.footer .volver {
  width: 9em;
}
.footer .volver p {
  font-family: "Open Sans", sans-serif;
}
.footer .laR {
  width: 16em;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .laR p {
  cursor: default;
}
.footer .laR img {
  height: 1rem;
  width: 1rem;
}
.footer .caja-redes {
  display: flex;
  flex-direction: column;
  width: 10rem;
  gap: 1rem;
}
.footer .caja-redes .segui {
  position: relative;
  pointer-events: none;
  display: flex;
  align-items: center;
}
.footer .caja-redes .segui p {
  margin: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  opacity: 1;
  width: -moz-fit-content;
  width: fit-content;
  cursor: default;
}
.footer .caja-redes .segui .sharef {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  pointer-events: auto;
}
.footer .caja-redes .redes {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  text-decoration: none;
}
.footer a {
  color: #ffffff;
}
.footer .facebook {
  color: #ffffff;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}
.footer .facebook:hover {
  color: rgb(235, 85, 0);
}
.footer .linkedin {
  color: #ffffff;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}
.footer .linkedin:hover {
  color: rgb(235, 85, 0);
}
.footer .instagram {
  color: #ffffff;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}
.footer .instagram:hover {
  color: rgb(235, 85, 0);
}

.footer-negro {
  background-color: white;
  color: black !important;
}
.footer-negro a {
  color: black !important;
}

@media (max-width: 800px) {
  .footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
    height: 18.75rem;
    position: relative;
  }
  .footer .footerImagen {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    background-position: center;
    background-size: cover;
  }
  .footer .footerGradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    background: linear-gradient(180deg, rgba(11, 11, 11, 0) 10.41%, rgba(11, 11, 11, 0) 10.41%, #000 100.02%, #000 100.86%);
  }
  .footer .volverAlHome {
    display: none;
    z-index: 5;
  }
  .footer .laR {
    grid-row: 2;
    width: 100%;
    align-items: center;
    display: flex;
    font-size: clamp(1rem, 5vw, 2rem);
    z-index: 5;
    gap: 0.7rem;
  }
  .footer .laR svg {
    width: 25px;
    height: 25px;
  }
  .footer .caja-redes {
    grid-row: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-self: center;
    width: 15rem;
    z-index: 5;
  }
  .footer .caja-redes .segui {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .footer .caja-redes .segui p {
    font-size: 2rem;
  }
  .footer .caja-redes .redes {
    width: 100%;
  }
  .footer .caja-redes svg {
    width: 40px;
    height: 40px;
  }
  .footer-with-image {
    height: 75rem !important;
    grid-template-rows: repeat(6, 1fr) !important;
  }
  .footer-with-image .laR {
    grid-row: 6 !important;
  }
  .footer-with-image .caja-redes {
    grid-row: 5 !important;
  }
  .footer-negro {
    background-color: black;
    color: white !important;
  }
  .footer-negro a {
    color: white !important;
  }
  .footer-negro-mobile {
    background-color: white;
    color: black !important;
  }
  .footer-negro-mobile a {
    color: black !important;
  }
}/*# sourceMappingURL=footer.css.map */