.main-servicios {
  background-color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.main-servicios .background-servicios {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-height: 60.5rem;
  gap: 4rem;
}
.main-servicios .background-servicios .servicios-video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.main-servicios .background-servicios .servicios-background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(11, 11, 11, 0) 10.41%, rgba(11, 11, 11, 0) 10.41%, #0b0b0b 100.02%, #0b0b0b 100.86%);
  z-index: 1;
  display: block;
}
.main-servicios .servicios-contenedor {
  position: relative;
  width: 100%;
  padding: 0 4rem;
  z-index: 2;
  padding-top: 10rem;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6rem;
}
.main-servicios .servicios-contenedor h1 {
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: 11rem;
  padding: 0%;
  cursor: default;
}
.main-servicios .servicios-contenedor p {
  font-family: NohemiRegular;
  font-weight: 100;
  font-size: 1.3rem;
  line-height: 120%;
  height: -moz-max-content;
  height: max-content;
  margin: 0%;
  padding: 0;
  align-self: flex-start;
  max-width: 37.5rem;
  cursor: default;
}
.main-servicios .servicios-explicacion-contenedor {
  background-color: #0b0b0b;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
  gap: 4rem;
  padding: 0 4rem;
  justify-content: center;
  position: relative;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion {
  border-radius: 18px;
  width: 100%;
  min-height: 40rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(212, 212, 212, 0.08);
  color: white;
  position: relative;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion .animation-spacer {
  width: 100%;
  height: 0;
  overflow: hidden;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion .whiteDot {
  transform-origin: top center;
  background-position: center;
  background-size: cover;
  position: absolute;
  height: 0;
  width: 0;
  top: 10px;
  right: 0px;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion .contenedor-servicios-explicacion-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 430px;
  overflow: hidden;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion .servicios-explicacion-image {
  width: 100%;
  height: 430px;
  opacity: 0;
  transform-origin: top center;
  background-position: center;
  background-size: cover;
  mix-blend-mode: exclusion;
  position: relative;
  z-index: 20;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion h1 {
  padding: 0 1rem;
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: 7rem;
  cursor: default;
}
.main-servicios .servicios-explicacion-contenedor .servicios-explicacion p {
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  padding: 0 1rem;
  padding-bottom: 2rem;
  cursor: default;
}
.main-servicios .servicios-explicacion-contenedor .consultoria-contenedor {
  grid-column: 1/-1;
  padding: 2rem 1rem;
  border-radius: 18px;
  height: 25rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(4.4000000954px);
          backdrop-filter: blur(4.4000000954px);
  background-color: rgba(212, 212, 212, 0.08);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  color: white;
}
.main-servicios .servicios-explicacion-contenedor .consultoria-contenedor h1 {
  width: -moz-fit-content;
  width: fit-content;
  font-family: HumaneRegular;
  font-weight: 100;
  font-size: 7rem;
  margin: 0%;
  cursor: default;
}
.main-servicios .servicios-explicacion-contenedor .consultoria-contenedor p {
  width: 100%;
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: 100;
  font-size: 1.3rem;
  cursor: default;
}
.main-servicios .servicios-explicacion-contenedor .consultoria-contenedor .consultoria-button {
  text-align: end;
  display: flex;
  justify-content: flex-end;
}
.main-servicios .servicios-explicacion-contenedor .consultoria-contenedor .consultoria-button .boton {
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid white;
  transition: background-color 0.3s, color 0.3s;
  font-size: 1.625rem;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}
.main-servicios .servicios-explicacion-contenedor .consultoria-contenedor .consultoria-button .boton:hover {
  color: black;
}
.main-servicios .final-contenedor {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-servicios .final-contenedor .final-text {
  width: -moz-max-content;
  width: max-content;
  height: -moz-max-content;
  height: max-content;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-servicios .final-contenedor .final-text .final-svg-1 {
  height: -moz-max-content;
  height: max-content;
  width: -moz-max-content;
  width: max-content;
}
.main-servicios .final-contenedor .final-text .final-svg-1 .svg path {
  fill: #eb5500;
}
.main-servicios .final-contenedor .final-text .final-svg-1 img {
  width: 120px;
  height: 120px;
  padding: 1rem;
}
.main-servicios .final-contenedor .final-text .final-svg-2 {
  height: 100%;
  width: -moz-max-content;
  width: max-content;
}
.main-servicios .final-contenedor .final-text .final-svg-2 img {
  width: 120px;
  height: 120px;
  padding: 1rem;
}
.main-servicios .final-contenedor .final-text p {
  font-family: "Encode Sans Condensed", sans-serif;
  font-weight: 100;
  font-size: 1.6rem;
  color: rgb(255, 255, 255);
  padding: 2rem;
  cursor: default;
}
.main-servicios .final-svg-3 {
  text-align: center;
}

@media (max-width: 1500px) {
  .main-servicios .final-contenedor .final-text {
    flex-direction: column;
    margin: 3rem 0;
  }
}
@media (max-width: 800px) {
  .main-servicios .pie-de-pagina-b {
    display: none;
  }
  .main-servicios .servicios-explicacion-contenedor {
    grid-template-columns: repeat(auto-fill, minmax(clamp(20rem, 5rem + 40vw, 40rem), 1fr));
  }
  .main-servicios .servicios-explicacion-contenedor .servicios-explicacion {
    min-height: 25rem;
    height: auto;
  }
  .main-servicios .servicios-explicacion-contenedor .consultoria-contenedor {
    min-height: 25rem;
    height: auto;
    grid-template-rows: auto;
    gap: 2rem;
  }
  .main-servicios .servicios-explicacion-contenedor .consultoria-contenedor h1 {
    color: white;
  }
  .main-servicios .final-contenedor .final-text img {
    display: none;
  }
  .main-servicios .final-svg-3 img {
    display: none;
  }
}/*# sourceMappingURL=servicios.css.map */