.main-servicios {
  background-color: #0b0b0b;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  .background-servicios {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: auto;
    min-height: 60.5rem;
    gap: 4rem;
    .servicios-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .servicios-background-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(11, 11, 11, 0) 10.41%,
        rgba(11, 11, 11, 0) 10.41%,
        #0b0b0b 100.02%,
        #0b0b0b 100.86%
      );
      z-index: 1;
      display: block;
    }
  }
  .servicios-contenedor {
    position: relative;
    width: 100%;
    padding: 0 4rem;
    z-index: 2;
    padding-top: 10rem;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6rem;

    h1 {
      font-family: HumaneRegular;
      font-weight: 100;
      font-size: 11rem;
      padding: 0%;
      cursor: default;
    }
    p {
      font-family: NohemiRegular;
      font-weight: 100;
      font-size: 1.3rem;
      line-height: 120%;
      height: max-content;
      margin: 0%;
      padding: 0;
      align-self: flex-start;
      max-width: 37.5rem;
      cursor: default;
    }
  }
  .servicios-explicacion-contenedor {
    background-color: #0b0b0b;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
    gap: 4rem;
    padding: 0 4rem;
    justify-content: center;
    position: relative;
    .servicios-explicacion {
      border-radius: 18px;
      width: 100%;
      min-height: 40rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(212, 212, 212, 0.08);
      color: white;
      position: relative;
      .animation-spacer {
        width: 100%;
        height: 0;
        overflow: hidden;
      }
      .whiteDot {
        transform-origin: top center;
        background-position: center;
        background-size: cover;
        position: absolute;
        height: 0;
        width: 0;
        top: 10px;
        right: 0px;
        border-radius: 50%;
        background-color: white;
        z-index: 1;
      }
      .contenedor-servicios-explicacion-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 430px;
        overflow: hidden;
      }
      .servicios-explicacion-image {
        width: 100%;
        height: 430px;
        opacity: 0;
        transform-origin: top center;
        background-position: center;
        background-size: cover;
        mix-blend-mode: exclusion;
        position: relative;
        z-index: 20;
      }
      h1 {
        padding: 0 1rem;
        font-family: HumaneRegular;
        font-weight: 100;
        font-size: 7rem;
        cursor: default;
      }
      p {
        font-family: "Encode Sans Condensed", sans-serif;
        font-weight: 100;
        font-size: 1.3rem;
        padding: 0 1rem;
        padding-bottom: 2rem;
        cursor: default;
      }
    }
    .consultoria-contenedor {
      grid-column: 1 / -1;
      padding: 2rem 1rem;
      border-radius: 18px;
      height: 25rem;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(4.400000095367432px);
      background-color: rgba(212, 212, 212, 0.08);
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      align-items: center;
      color: white;
      h1 {
        width: fit-content;
        font-family: HumaneRegular;
        font-weight: 100;
        font-size: 7rem;
        margin: 0%;
        cursor: default;
      }
      p {
        width: 100%;
        font-family: "Encode Sans Condensed", sans-serif;
        font-weight: 100;
        font-size: 1.3rem;
        cursor: default;
      }
      .consultoria-button {
        text-align: end;
        display: flex;
        justify-content: flex-end;
        .boton {
          color: white;
          padding: 10px 20px;
          border-radius: 10px;
          border: 1px solid white;
          transition: background-color 0.3s, color 0.3s;
          font-size: 1.625rem;
          width: fit-content;
          text-align: center;
          cursor: pointer;
        }
        .boton:hover {
          color: black;
        }
      }
    }
  }
  .final-contenedor {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .final-text {
      width: max-content;
      height: max-content;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .final-svg-1 {
        height: max-content;
        width: max-content;
        .svg path {
          fill: #eb5500;
        }
        img {
          width: 120px;
          height: 120px;
          padding: 1rem;
        }
      }
      .final-svg-2 {
        height: 100%;
        width: max-content;

        img {
          width: 120px;
          height: 120px;
          padding: 1rem;
        }
      }
      p {
        font-family: "Encode Sans Condensed", sans-serif;
        font-weight: 100;
        font-size: 1.6rem;
        color: rgba(255, 255, 255, 1);
        padding: 2rem;
        cursor: default;
      }
    }
  }
  .final-svg-3 {
    text-align: center;
  }
}
@media (max-width: 1500px) {
  .main-servicios .final-contenedor .final-text {
    flex-direction: column;
    margin: 3rem 0;
  }
}
@media (max-width: 800px) {
  .main-servicios {
    .pie-de-pagina-b {
      display: none;
    }
    .background-servicios {
    }
    .servicios-explicacion-contenedor {
      grid-template-columns: repeat(
        auto-fill,
        minmax(clamp(20rem, 5rem + 40vw, 40rem), 1fr)
      );
      .servicios-explicacion {
        min-height: 25rem;
        height: auto;
      }
      .consultoria-contenedor {
        min-height: 25rem;
        height: auto;
        grid-template-rows: auto;
        gap: 2rem;
        h1 {
          color: white;
        }

        .consultoria-button a {
        }
      }
    }
    .final-contenedor .final-text img {
      display: none;
    }
    .final-svg-3 img {
      display: none;
    }
  }
}
