.class-contenedor {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #0b0b0b;
}
.contacto-home-contenedor {
  width: 100%;
  height: 100svh;
  min-height: 39rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../img/contenedor.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contacto-background-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(11, 11, 11, 0) 10.41%,
    rgba(11, 11, 11, 0) 10.41%,
    #0b0b0b 100.02%,
    #0b0b0b 100.86%
  );
  z-index: 1;
}
.contacto-home-contenedor h1 {
  color: white;
  font-size: clamp(7rem, 2rem + 11vw, 13.5rem);
  font-family: HumaneRegular;
  font-weight: 100;
  cursor: default;
  z-index: 2;
}
.tarjeta-contacto-contenedor {
  width: 100%;
  height: auto;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contacto-tarjeta-borde {
  width: 1405px;
  height: 564px;
  display: flex;
  flex-direction: column;
  padding-left: 5rem;
  align-items: start;
  border: solid 1px rgba(253, 248, 248, 1);
  border-radius: 5px;
}

.contacto-tarjeta-titulo {
  width: 95%;
  height: 15%;
  display: flex;
  align-items: center;
}
.contacto-tarjeta-titulo h1 {
  padding-top: 60px;
  color: white;
  font-size: 2.5rem;
  font-family: NohemiRegular;
  text-transform: uppercase;
  line-height: normal;
  font-style: normal;
  font-weight: 500;
  height: fit-content;
  cursor: default;
}
.contacto-tarjeta-texto-p {
  width: 100%;
  display: grid;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-right: 3rem;
}
.contacto-tarjeta-texto-p p {
  color: #fff;
  font-size: 1.5rem;
  font-family: NohemiRegular;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: default;
}
.contacto-tarjeta-text {
  width: 95%;
  height: 85%;

  display: grid;
  grid-template-columns: 2fr 1fr;
}
.contacto-tarjeta-redes {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: center;
  gap: 2.3rem;
}
.contacto-tarjeta-redes p {
  color: rgba(235, 85, 0, 1);
  font-size: 32px;
  font-family: NohemiRegular;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
  cursor: default;
}
.foco-img-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.7rem;
}
.foco-img-text p {
  font-size: 15px;
}
.foco-contenedor img {
  width: 49px;
  height: auto;
}
.svg-contenedor {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.svg-contenedor svg {
  font-size: 26px;
  margin: 10px;
  color: rgba(235, 85, 0, 1);
  transition: all 0.4s ease;
}
.svg-contenedor svg:hover {
  color: white;
}
.formulario-contenedor {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(625px, 1fr));
  gap: 6rem 6rem;
  padding: 4rem 12rem;
}
.formulario-contenedor .text-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  justify-self: flex-start;
  color: #ffffff;
  align-items: flex-start;
  font-size: 1.875rem;
  font-family: NohemiRegular;
  text-transform: uppercase;
  cursor: default;
}
.formulario-contenedor .text-input input {
  width: 100%;
  height: 2rem;
  font-size: 1.5rem;
  padding-bottom: 5px;
}
.formulario-contenedor .text-checkbox-contenedor {
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.formulario-contenedor .text-checkbox-contenedor h1 {
  color: white;
  cursor: default;
}
.formulario-contenedor .text-checkbox-contenedor .checkbox-contenedor {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.formulario-contenedor .text-checkbox-contenedor .checkbox-contenedor label {
  cursor: pointer;
  border: solid 1px rgb(255, 255, 255);
  padding: 5px;
  border-radius: 5px;
  color: white;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-family: NohemiRegular;
  min-width: 130px;
  cursor: default;
}

.formulario-contenedor .text-checkbox-contenedor .checkbox-contenedor .active {
  color: rgba(235, 85, 0, 1);
  border-color: rgba(235, 85, 0, 1);
}

.formulario-contenedor .text-checkbox-contenedor .checkbox-contenedor input {
  display: none;
}
.formulario-contenedor span {
  color: #eb5500;
}
.formulario-contenedor li {
  list-style: none;
  color: white;
  padding-bottom: 22px;
  padding-top: 17px;
}
.formulario-contenedor input {
  border: none;
  outline: none;
  background: none;
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  width: 12rem;
}

.formulario-contenedor .formulario-solo-centro {
  grid-column: 1 / -1;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
}
.formulario-solo-centro p {
  color: white;
  font-size: 2rem;
  font-family: NohemiRegular;
  cursor: default;
}
.formulario-solo-centro input {
  border: none;
  outline: none;
  background: none;
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.507);
  width: 100%;
  height: 2rem;
  font-size: 1.5rem;
  padding-bottom: 5px;
}
.formulario-contenedor .mensaje-contenedor {
  grid-column: 1 / -1;
  width: 100%;
  height: 27rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  text-transform: uppercase;
}

.formulario-contenedor .mensaje-contenedor h1 {
  font-size: 2rem;
  color: white;
  cursor: default;
}
.formulario-contenedor .mensaje-contenedor textarea {
  border: solid 2px white;
  outline: none;
  background-color: rgba(11, 11, 11, 1);
  resize: none;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  color: white;
  background-image: url(../../svg/carita.svg);
  background-repeat: no-repeat;
  font-size: 1.5rem;
}
.formulario-contenedor .mensaje-contenedor textarea:hover {
  background-image: url(../../svg/carita-2.svg);
}
.contactanos-button {
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: NohemiRegular;
  gap: 1rem;
  cursor: default;
}
.contactanos-button p {
  font-size: 2rem;
  color: white;
}
.proyectosButton {
  border: 1px solid white;
}
.proyectosButton .contactoButton {
  padding: 1rem 1.5rem;
  font-size: 1.25rem;
  font-family: NohemiRegular;
  transition: all 0.5s linear;
  color: white;
}
.proyectosButton .contactoButton:hover {
  color: #0b0b0b;
}

.contactoButton {
  border: none;
  background: none;
}
@media (max-width: 1430px) {
  .contacto-tarjeta-text {
    grid-template-columns: 1fr;
  }
  .contacto-tarjeta-borde {
    width: fit-content;
    height: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .contacto-tarjeta-texto-p {
    text-align: center;
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
  .contacto-tarjeta-titulo h1 {
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 2.5rem);
  }
  .contacto-tarjeta-redes p {
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
}
@media (max-width: 50rem) {
  .contacto-tarjeta-borde {
    padding-left: 1rem;
  }

  .contacto-tarjeta-texto-p {
    padding-left: 1rem;
    text-align: center;
  }
  .formulario-contenedor {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(
      auto-fill,
      minmax(clamp(25rem, 15rem + 40vw, 45rem), 1fr)
    );
    gap: 6rem 6rem;
    padding: 4rem 12rem;
  }
  .contactanos-button {
    grid-column: 1 / -1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: NohemiRegular;
    gap: 2rem;
  }
  .contactanos-button a {
    text-align: center;
  }
}
@media (max-width: 31.25rem) {
  .formulario-contenedor {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(
      auto-fill,
      minmax(clamp(15rem, 5rem + 60vw, 25rem), 1fr)
    );
    gap: 6rem 6rem;
    padding: 4rem 12rem;
  }
}
