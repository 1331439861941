@font-face {
  font-family: NohemiRegular;
  src: url("./fonts/Nohemi/Web-PS/Nohemi-Regular.otf");
}
@font-face {
  font-family: HumaneRegular;
  src: url("./fonts/HUMANE/Web-PS/Humane-Regular.otf");
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: none;
}
body {
  width: calc(100vw - (100vw - 100%));
  min-height: 100vh;
  overflow-x: hidden;
  animation: fadeIn 1s ease-out forwards;
}
.main {
  background-color: rgba(11, 11, 11, 1);
  width: 100%;
}
.light {
  background-color: white !important;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.transitionSvgContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.transitionSvgContainer .transitionSvg {
  fill: rgba(7, 7, 7, 1);
}