@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@font-face {
  font-family: "Encode Sans Condensed";
  src: url(https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap);
}
.hidden {
  display: none !important;
}
.nav {
  width: 100%;
  background: rgba(0, 0, 0, 0);
  height: 6.25rem;
  overflow: hidden;
  flex-direction: WRAP;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  .hide-on-mobile {
    display: block;
  }
  .hide-on-desktop {
    display: none;
  }
  .logo {
    width: auto;
    height: 2.5rem;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-top: 0.5%;
  }
  .menu-negro {
    color: black !important;
  }
  .menu-hambur {
    width: 7em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    color: white;
    font-size: 1.5rem;
    cursor: default;
  }
  .logo-contenedor {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 3.3rem;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 2rem;
    width: 1.6rem;
    .puntito {
      background-color: white;
      height: 8px;
      width: 8px;
      position: absolute;
      right: 0 !important;
      top: 0;
      border-radius: 50%;
      opacity: 1;
    }
    .puntito-blanco {
      background-color: black;
      height: 8px;
      width: 8px;
      position: absolute;
      right: 0 !important;
      top: 0;
      border-radius: 50%;
      opacity: 1;
    }
    .puntito-naranja {
      background-color: rgba(235, 85, 0, 1);
      height: 8px;
      width: 8px;
      position: absolute;
      right: 0 !important;
      top: 0;
      border-radius: 50%;
      transform: translateX(-60px);
    }
  }
  .logo-contenedor:hover {
    .puntito-naranja {
      transform: translateX(0px);
      animation: rebotederecha 0.8s ease-out forwards;
    }
    .puntito {
      transform: translateX(60px);
      animation: reboteizquierda 0.8s ease-out forwards;
    }
  }
}
.nav-negro {
  p {
    color: black !important;
  }
  .menu-hambur {
    .hambur {
      color: black !important;
    }
  }
  .puntito {
    background-color: black !important;
  }
}
.menu-items {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  animation: fadeIn 2s ease-out forwards;
  .contenedor-ul {
    width: 30em;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7em;
    .nuestro {
      position: relative;
      overflow: hidden;
      width: max-content;
      height: 7em;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .text-top {
        position: absolute;
        width: max-content;
        height: 100%;
        text-align: center;
        transition: transform 0.2s ease, opacity 0.5s ease;
        top: 0;
      }
      .text-bottom {
        position: relative;
        top: 100%;
        height: 100%;
        opacity: 0;
        transition: transform 0.3s ease, opacity 0s ease;
      }
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 6rem;
        color: rgba(255, 255, 255, 1);
        font-family: HumaneRegular;
        a {
          color: white;
        }
      }
    }
    .nuestro:hover {
      .text-top {
        transform: translateY(-100%);
        opacity: 0;
      }
      .text-bottom {
        transform: translateY(-100%);
        opacity: 1;
        color: rgba(235, 85, 0, 1);
      }
    }
  }
}

@media (max-width: 800px) {
  .nav {
    .logo-contenedor {
      height: 2.1rem;
      width: 1.1rem;
      .puntito {
        width: 6px;
        height: 6px;
      }
      .puntito-blanco {
        width: 6px;
        height: 6px;
      }
      .puntito-naranja {
        width: 6px;
        height: 6px;
      }
      .logo {
        height: 1.5rem;
      }
    }
    .hide-on-mobile {
      display: none;
    }
    .hide-on-desktop {
      display: block;
    }
    .menu-hambur {
      width: 5rem;
      p {
        display: none;
      }
    }
  }
  .nav-negro {
    p {
      color: white !important;
    }
    .menu-hambur {
      .hambur {
        color: white !important;
      }
    }
    .puntito {
      background-color: white !important;
    }
  }
  .nav-negro-mobile {
    p {
      color: black !important;
    }
    .menu-hambur {
      .hambur {
        color: black !important;
      }
    }
    .puntito {
      background-color: black !important;
    }
  }
}

@keyframes rebotederecha {
  0% {
    transform: translateX(-60px);
  }
  80% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes reboteizquierda {
  100% {
    transform: translateX(60px);
  }
  20% {
    transform: translateX(-5px);
  }
  0% {
    transform: translateX(0px);
  }
}
@keyframes reboteizquierdastart {
  100% {
    transform: translateX(0px);
  }
  80% {
    transform: translateX(-5px);
  }
  0% {
    transform: translateX(60px);
  }
}
@keyframes rebotederechastart {
  100% {
    transform: translateX(-60px);
  }
  20% {
    transform: translateX(5px);
  }
  0% {
    transform: translateX(0px);
  }
}
