.cards {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 100px 0;
  scroll-snap-align: center;
}
.cards .card {
  position: relative;
}
.cards .card .card__inner {
  will-change: transform;
  background: transparent;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  transform-origin: center top;
}
.cards .card .card__inner .card__image-container {
  display: flex;
  width: 100%;
  height: clamp(400px, 10rem + 60vh, 50rem);
  flex-shrink: 0;
}
.cards .card .card__inner .card__image-container .mobile_card {
  display: none;
}
.cards .card .card__inner .card__image-container .card__image {
  width: 100%;
  height: 100%;
  background-size: 100% 107.028%;
  background-position: 0px -34.857px;
  aspect-ratio: 1;
}

.space {
  height: 2rem;
}

@media (max-width: 800px) {
  .card__inner .card__image-container .mobile_card {
    display: block !important;
  }
  .card__inner .card__image-container .card__image {
    display: none;
    background-size: 100% 100% !important;
    background-position: 0 !important;
  }
}
@media (max-width: 600px) {
  .card__inner {
    flex-direction: column;
  }
  .card__inner .card__image-container {
    width: 100%;
    height: clamp(21.875rem, 10rem + 40vh, 31.25rem) !important;
  }
  .card__inner .card__image-container .card__image {
    aspect-ratio: 16/9;
  }
}/*# sourceMappingURL=carrusel.css.map */