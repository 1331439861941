.sobre-nosotros-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  background-color: #0b0b0b;
  .seccionBlanca {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-image: url("../../img/backgroundSobreNosotrosMobile.webp");
    background-size: cover;
    background-position: center;
    background-color: white;
    align-items: center;
    .sobre-nosotros {
      padding-top: 2rem;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 0 3rem;
      .nosotros-background-gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(11, 11, 11, 0) 10.41%,
          rgba(11, 11, 11, 0) 10.41%,
          #0b0b0b 100.02%,
          #0b0b0b 100.86%
        );
        z-index: 1;
        display: none;
      }
      .nosotros-titulo {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;
        grid-column: span 2;
        z-index: 2;
        height: 50rem;
        padding-top: 10rem;
        h1 {
          font-family: HumaneRegular;
          font-weight: 100;
          font-size: clamp(5rem, 2rem + 6vw, 8rem);
          color: white;
          margin: 0%;
          cursor: default;
        }
        p {
          color: white;
          font-family: NohemiRegular;
          font-size: clamp(1rem, 1vw, 1.5rem);
          font-style: normal;
          font-weight: 100;
          line-height: 120%;
          max-width: 37.5rem;
          cursor: default;
        }
      }
    }
    .pie-de-pagina-b {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 4rem;

      p {
        z-index: 100;
        font-size: 1rem;
        color: white;
        font-family: "Open Sans", sans-serif;
        cursor: default;
      }
      a {
        z-index: 100;
      }
    }
    .nuestra-vision {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40rem, 1fr));
      gap: 6rem;
      padding: 0 3rem;
      justify-content: center;

      .nuestra-text {
        border-radius: 10px;
        background: rgba(212, 212, 212, 0.08);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(4.400000095367432px);
        display: flex;
        flex-direction: column;
        padding: 4rem 1.5rem;
        h1 {
          font-family: HumaneRegular;
          font-weight: 100;
          font-size: clamp(4rem, 8vw, 8rem);
          cursor: default;
          color: white;
          padding: 0 1rem;
        }
        p {
          font-family: NohemiRegular;
          font-weight: 100;
          font-size: clamp(1rem, 1.5vw, 1.5625rem);
          padding: 0 1rem;
          color: white;
          cursor: default;
        }
      }
    }
    .comprendemos {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: end;
      padding-bottom: 2rem;
      .comprendemos-text {
        width: max-content;
        text-align: center;
        height: max-content;
        p {
          color: white;
          font-family: "Encode Sans Condensed", sans-serif;
          font-weight: 100;
          margin: 0%;
          font-size: 1.5rem;
          cursor: default;
        }
      }
    }
  }
  .seccionNegra {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #0b0b0b;
    .equipo-contenedor {
      width: 100%;
      height: 30em;
      padding-top: 4rem;
      .equipo-text {
        width: 100%;
        height: max-content;
        text-align: center;
        h1 {
          margin: 0%;
          font-family: HumaneRegular;
          font-weight: 100;
          font-size: 14rem;
          color: rgb(255, 255, 255);
          padding: 0 1rem;
          cursor: default;
        }
        h2 {
          font-family: NohemiRegular;
          font-weight: 100;
          margin: 0%;
          font-size: 1.5rem;
          color: #484848;
          cursor: default;
        }
      }
    }
    
    .contacto-final {
      width: 100%;
      height: 20em;
      display: flex;
      justify-content: center;
      align-items: end;

      h1 {
        font-size: 11rem;
        color: white;
        font-family: HumaneRegular;
        font-weight: 100;
        margin: 0%;
        padding: 0%;
      }
    }
    hr {
      padding: 0%;
      margin: 0%;
    }
  }
}

@media (max-width: 800px) {
  .sobre-nosotros-container {
    padding-top: 0;
    .seccionBlanca {
      background-color: #0b0b0b;
      background-image: none;
      .sobre-nosotros {
        background-color: #0b0b0b;
        background-image: url("../../img/backgroundSobreNosotrosMobile.webp");
        background-size: 150%;
        background-position: center;
        gap: 13rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 600px;
        .nosotros-background-gradient {
          display: block;
        }
        .nosotros-titulo {
          padding: 6rem 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h1 {
            color: white;
          }
          p {
            color: white;
            align-self: flex-end;
          }
        }
      }

      .nuestra-vision {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: #0b0b0b;
        .nuestra-text {
          display: flex;
          flex-direction: column;
          padding: 4rem 1.5rem;
          background-color: rgba(212, 212, 212, 0.08);
          h1 {
            color: white;
            padding: 0;
          }
          p {
            color: white;
            padding: 0;
          }
        }
      }
      .comprendemos {
        background-color: #0b0b0b;
        .comprendemos-text {
          padding: 0 2rem;
          p {
            color: white;
          }
        }
      }
    }
    .seccionNegra {
      .equipo-contenedor {
        .equipo-text {
          h1 {
            font-size: clamp(7rem, 3rem + 20vw, 14rem);
          }
          h2 {
            font-size: 1rem;
          }
        }
      }
      .contacto-final {
        height: auto;
        padding: 4rem 0;
        h1 {
          font-size: clamp(7rem, 3rem + 20vw, 14rem);
        }
      }
    }
  }
}
